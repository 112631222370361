.component.section-intro .section-title {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    padding-top: 280px;
    position: relative;
}

.component.section-intro .section-title .title {
    color: var(--color-neutral-white);
    width: var(--grid-width-10-col);
    margin: 0 auto;
}

.component.section-intro .section-title .transparent-to-color-fade {
    height: 328px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.component.section-intro .intro-text {
    width: var(--grid-width-10-col);
    margin: 160px auto 0 auto;
    display: flex;
    justify-content: space-between;
    color: var(--color-neutral-black);
}

.component.section-intro .intro-text .left-col {
    width: var(--grid-width-4-col);
    padding-top: 24px;
    padding-bottom: 88px;
    border-top: 1px solid var(--color-neutral-black);
    margin-top: 8px;
}

.component.section-intro .intro-text .right-col {
    width: var(--grid-width-5-col);
}

.component.section-intro img.earbuds {
    margin-left: calc(var(--grid-width-1-col) + calc(var(--grid-width-1-col) * 0.5));
}

@media (max-width: 1368px) {
    .component.section-intro .intro-text .right-col {
        width: var(--grid-width-6-col);
    }
}

@media (max-width: 768px) {
    .component.section-intro > .container {
        display: flex;
        flex-direction: column;
    }

    .component.section-intro .intro-text {
        flex-direction: column;
        width: 100%;
    }

    .component.section-intro .intro-text .left-col,
    .component.section-intro .intro-text .right-col {
        width: 100%;
    }

    .component.section-intro .intro-text .left-col {
        margin-bottom: 24px;
    }

    .component.section-intro .intro-text .right-col {
        font-size: 21px;
        line-height: 28px;
    }

    .component.section-intro img.earbuds {
        margin: 80px auto 0 auto;
    }
}

@media (max-width: 650px) {
    .component.section-intro .section-title {
        padding-top: 0;
    }

    .component.section-intro .section-title .title {
        font-size: 48px;
        line-height: 50px;
        position: absolute;
        top: calc(100vh - 450px);
    }

    .component.section-intro .section-title .title br {
        display: none;
    }
}

@media (max-width: 500px) {
    .component.section-intro img.earbuds {
        width: 100%;
    }
}
