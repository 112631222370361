.outro {
    color: var(--color-neutral-white);
    background-color: var(--color-neutral-black);
    padding-bottom: 56px;
    overflow: hidden;
    position: relative;
    z-index: 6;
}

.outro .component.ticker {
    margin-bottom: 256px;
}

.outro .here-for-it {
    width: var(--grid-width-10-col);
    margin: 0 auto 256px auto;
    display: flex;
    justify-content: space-between;
}

.outro .here-for-it .description {
    width: var(--grid-width-5-col);
}

.outro .what-does-this-mean {
    width: var(--grid-width-8-col);
    margin-bottom: 256px;
    display: flex;
    justify-content: center;
}

.outro .what-does-this-mean .box-contents {
    padding: 54px 94px;
}

.outro .what-does-this-mean .title {
    width: 395px;
    margin-bottom: 48px;
}

.outro .video {
    width: var(--grid-width-8-col);
    margin: 0 auto 56px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: 1px solid var(--color-neutral-white);
}

.outro .video .vimeo-iframe {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.outro .video .vimeo-iframe iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.outro .back-to-top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.outro .back-to-top .icon {
    cursor: pointer;
}

.outro .back-to-top > *:nth-child(2) {
    position: absolute;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.outro .back-to-top .icon:first-child {
    animation: rotate 15s linear infinite;
}

@media (max-width: 1368px) {
    .outro .what-does-this-mean {
        width: var(--grid-width-12-col);
    }
}

@media (max-width: 1200px) {
    .outro .here-for-it .title {
        margin-right: var(--grid-offset-1-col);
    }

    .outro .here-for-it .description {
        width: var(--grid-width-7-col);
    }
}

@media (max-width: 768px) {
    .outro {
        padding-bottom: 128px;
    }

    .outro .here-for-it {
        flex-direction: column;
    }

    .outro .here-for-it .title {
        margin-right: 0;
        margin-bottom: 40px;
        font-size: 56px;
        line-height: 50px;
    }

    .outro .here-for-it .description {
        font-size: 21px;
        line-height: 28px;
    }

    .outro .what-does-this-mean .box-contents {
        padding: 24px 37px 12px 24px;
    }

    .outro .what-does-this-mean .box-contents .title {
        font-size: 36px;
        line-height: 34px;
        width: 100%;
    }

    .outro .what-does-this-mean .box-contents .description {
        font-size: 18px;
        line-height: 24px;
    }

    .outro .video {
        margin-bottom: 128px;
    }
}
