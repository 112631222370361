.component.thought-bubble {
    position: relative;
    display: inline-block;
}

.component.thought-bubble .background-gradient {
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0;
    left: 0;
    z-index: 0;
}

.component.thought-bubble .main-section {
    position: relative;
    z-index: 1;
    display: flex;
    transition: 0.5s transform;
}

.component.thought-bubble .main-section .left-col {
    width: auto;
    margin-right: 24px;
}

.component.thought-bubble .main-section .left-col .title {
    margin-bottom: 48px;
}

.component.thought-bubble .main-section .right-col img {
    width: 291px;
}

.component.thought-bubble .main-section .missing-image {
    width: 281px;
    height: 480px;
    background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 1368px) {
    .component.thought-bubble .main-section .left-col .title {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 24px;
    }

    .component.thought-bubble .main-section .left-col .description {
        font-size: 18px;
        line-height: 24px;
    }
}

@media (max-width: 768px) {
    .component.thought-bubble .main-section {
        flex-direction: column;
    }

    .component.thought-bubble .main-section .left-col {
        margin-right: 0;
        margin-bottom: 24px;
    }

    .component.thought-bubble .main-section .right-col img {
        width: 100%;
    }
}
