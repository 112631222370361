.component.ticker {
    display: flex;
    text-transform: uppercase;
}

.component.ticker .ticker-block {
    flex-shrink: 0;
    padding: 24px 48px;
}

.component.ticker .ticker-block.default {
    color: var(--color-neutral-black);
    background-image: url("../images/ticker-background.jpg");
    background-size: cover;
    background-position: center;
}

.component.ticker .ticker-block.outline {
    backdrop-filter: blur(8px);
}

@media (max-width: 768px) {
    .component.ticker .ticker-block {
        font-size: 64px;
        line-height: 68px;
        padding: 12px 24px;
    }
}
