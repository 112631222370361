.component.audio-player {
    border: 1px solid var(--color-neutral-black);
    display: flex;
}

.component.audio-player .audio-player-left-col {
    padding: 5px 43px 5px 16px;
}

.component.audio-player .audio-player-left-col .audio-title {
    display: flex;
    align-items: center;
    width: 257px;
    min-height: 42px;
    margin-bottom: 7px;
}

.component.audio-player .audio-player-left-col .playback {
    display: flex;
    align-items: center;
}

.component.audio-player .audio-player-left-col .playback .current-time,
.component.audio-player .audio-player-left-col .playback .total-time {
    width: 24px;
}

.component.audio-player .audio-player-left-col .playback > *:not(:last-child) {
    margin-right: 10px;
}

.component.audio-player .audio-player-left-col .playback .progress-bar {
    width: 191px;
    height: 3px;
    border: 1px solid var(--color-neutral-black);
}

.component.audio-player .audio-player-left-col .playback .progress-bar .current-progress {
    height: 100%;
    background-color: var(--color-neutral-black);
}

.component.audio-player .audio-player-right-col {
    position: relative;
    cursor: pointer;
    border-left: 1px solid var(--color-neutral-black);
}

.component.audio-player .audio-player-right-col .darken {
    background-color: var(--color-neutral-black);
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.3s opacity;
}

.component.audio-player .audio-player-right-col .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 73px;
    height: 100%;
}

@media (max-width: 420px) {
    .component.audio-player .audio-player-left-col {
        padding: 5px 15px 5px 16px;
    }

    .component.audio-player .audio-player-left-col .audio-title {
        width: auto;
    }

    .component.audio-player .audio-player-left-col .playback .progress-bar {
        width: 100%;
    }
}
