.footer {
    position: fixed;
    z-index: 8;
    bottom: 0;
    border-top: 0.5px solid var(--color-neutral-white);
    height: 56px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    background-color: var(--color-neutral-black);
    transition: 0.3s all;
}

.footer .footer-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-left: 56px;
    color: var(--color-neutral-white);
    cursor: pointer;
    transition: 0.3s background-color, 0.3s color, 0.3s filter;
}

.footer .footer-link .icon {
    padding-left: 8px;
    padding-right: 32px;
}

.footer .footer-link:not(:first-child) {
    border-left: 0.5px solid var(--color-neutral-dark-gray-2);
}

.footer .footer-link:not(:last-child) {
    border-right: 0.5px solid var(--color-neutral-dark-gray-2);
}

.footer .footer-link:hover {
    color: var(--color-neutral-black);
}

.footer .footer-link:first-child:hover {
    background-color: var(--color-neon-indigo);
}

.footer .footer-link:nth-child(2):hover {
    background-color: var(--color-neon-peach);
}

.footer .footer-link:nth-child(3):hover {
    background-color: var(--color-solid-sxm-blue);
}

.footer .footer-link:nth-child(4):hover {
    background-color: var(--color-solid-tangerine);
}

.footer .footer-link:active {
    filter: brightness(90%);
}

@media (max-width: 1100px) {
    .footer .footer-link {
        font-size: 12px;
        line-height: 14px;
        padding-left: 24px;
    }
}
