.navbar {
    position: fixed;
    z-index: 8;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    transition: 0.3s border-bottom-color;
}

.navbar .logo {
    cursor: pointer;
}

.navbar .logo svg,
.navbar .icon svg {
    transition: 0.3s fill;
}

.navbar .center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left-width: 0.5px;
    border-left-style: solid;
    border-right-width: 0.5px;
    border-right-style: solid;
    transition: 0.3s border-left-color, 0.3s border-right-color;
    flex-grow: 1;
    height: 100%;
    padding: 0 40px;
}

.navbar .center .current-page {
    text-transform: uppercase;
    transition: 0.3s color;
}

.navbar .center .current-page .component.outline-text {
    letter-spacing: 0.23em;
    transition: 0.3s all;
}

.navbar .center .buttons {
    display: flex;
    align-items: center;
}

.navbar .center .download-button {
    border-radius: 100px;
    border-width: 1px;
    border-style: solid;
    margin-right: 40px;
    padding: 2px;
    cursor: pointer;
    transition: 0.3s border-color, 0.3s background-color, 0.3s filter;
}

.navbar .center .download-button .icon {
    height: 20px;
}

.navbar .center .download-button .icon * {
    transition: 0.3s all;
}

.navbar .center .share-button {
    display: flex;
    height: 24px;
    padding: 4px 16px 4px 24px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    transition: 0.3s border-color, 0.3s background-color, 0.3s filter;
}

.navbar .center .share-button .title {
    text-transform: uppercase;
    margin-right: 10px;
    transition: 0.3s color;
}

.navbar .center .share-button .icon {
    display: flex;
    align-items: center;
}

.navbar .center .share-button .icon * {
    transition: 0.3s all;
}

.navbar .menu-icon {
    position: relative;
    width: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.navbar .menu-icon .icon {
    position: absolute;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.navbar .menu-icon .icon:first-child {
    height: 56px;
    animation: rotate 15s linear infinite;
}

@media (max-width: 768px) {
    .navbar .center {
        align-items: flex-end;
        padding: 0 12px;
    }

    .navbar .center .current-page,
    .navbar .center .download-button,
    .navbar .center .share-button {
        margin-bottom: 10px;
    }

    .navbar .center .download-button {
        margin-right: 16px;
    }

    .navbar .center .share-button {
        padding: 0;
        border-radius: 24px;
        padding: 0 4px;
    }

    .navbar .menu-icon {
        width: 80px;
    }
}

@media (max-width: 360px) {
    .navbar .center .current-page {
        font-size: 16px;
    }
}

/* Progress Bar */

.navbar .progress-bar-wrapper {
    position: absolute;
    width: 100%;
    bottom: -5px;
    transition: 0.3s opacity;
}

.navbar .progress-bar-border {
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    transition: 0.3s border-bottom-color;
}

.navbar .progress-bar {
    height: 4px;
    border-right-width: 0.5px;
    border-right-style: solid;
    transition: 0.3s border-right-color;
}

/* Expanded Menu */

.expanded-menu {
    position: fixed;
    z-index: 8;
    background-color: var(--color-neutral-black);
    color: var(--color-neutral-white);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.expanded-menu .row {
    border-bottom: 0.5px solid var(--color-neutral-dark-gray-2);
    padding: 40px 60px;
    display: flex;
    cursor: pointer;
    transition: 0.5s padding;
    position: relative;
}

.expanded-menu .row:hover {
    padding-top: 90px;
    padding-bottom: 90px;
}

.expanded-menu .row .section-number {
    width: 85px;
    margin-right: 60px;
    user-select: none;
}

.expanded-menu .row .section-title {
    user-select: none;
    z-index: 1;
}

.expanded-menu .row img {
    position: absolute;
    top: 8px;
    right: 60px;
    height: calc(100% - 16px);
    opacity: 0;
    transition: 0.5s opacity;
}

.expanded-menu .row:hover img {
    opacity: 1;
}

.expanded-menu .download-button-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    padding-left: 60px;
    padding-bottom: 88px;
}

.expanded-menu .download-button {
    display: flex;
    height: 24px;
    padding: 4px 16px 4px 24px;
    border: 1px solid var(--color-neutral-white);
    cursor: pointer;
    text-decoration: none;
}

.expanded-menu .download-button .title {
    color: var(--color-neutral-white);
    text-transform: uppercase;
    margin-right: 10px;
}

.expanded-menu .download-button .icon {
    display: inherit;
}

@media (max-width: 1120px) {
    .expanded-menu {
        font-size: 36px !important;
        line-height: 40px !important;
    }

    .expanded-menu .row .section-number {
        width: 55px;
        margin-right: 24px;
    }
}

@media (max-width: 768px) {
    .expanded-menu .row {
        padding: 30px 20px;
        align-items: center;
    }

    .expanded-menu .row:hover {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

@media (max-width: 360px) {
    .expanded-menu {
        font-size: 28px !important;
        line-height: 32px !important;
    }
}
