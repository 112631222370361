body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --grid-column-width: 88px;
    --grid-gap-width: 24px;

    --grid-offset-1-col: calc(var(--grid-column-width) + var(--grid-gap-width));

    --grid-width-1-col: calc(calc(var(--grid-column-width) * 1) + calc(var(--grid-gap-width) * 0));
    --grid-width-2-col: calc(calc(var(--grid-column-width) * 2) + calc(var(--grid-gap-width) * 1));
    --grid-width-3-col: calc(calc(var(--grid-column-width) * 3) + calc(var(--grid-gap-width) * 2));
    --grid-width-4-col: calc(calc(var(--grid-column-width) * 4) + calc(var(--grid-gap-width) * 3));
    --grid-width-5-col: calc(calc(var(--grid-column-width) * 5) + calc(var(--grid-gap-width) * 4));
    --grid-width-6-col: calc(calc(var(--grid-column-width) * 6) + calc(var(--grid-gap-width) * 5));
    --grid-width-7-col: calc(calc(var(--grid-column-width) * 7) + calc(var(--grid-gap-width) * 6));
    --grid-width-8-col: calc(calc(var(--grid-column-width) * 8) + calc(var(--grid-gap-width) * 7));
    --grid-width-9-col: calc(calc(var(--grid-column-width) * 9) + calc(var(--grid-gap-width) * 8));
    --grid-width-10-col: calc(calc(var(--grid-column-width) * 10) + calc(var(--grid-gap-width) * 9));
    --grid-width-11-col: calc(calc(var(--grid-column-width) * 11) + calc(var(--grid-gap-width) * 10));
    --grid-width-12-col: calc(calc(var(--grid-column-width) * 12) + calc(var(--grid-gap-width) * 11));
}

.container {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
}

/* for the rest of desktop 1368px and smaller, container should fill screen with gutters the same width as the gaps */
@media (max-width: 1368px) {
    body {
        --grid-column-width: calc(100% / calc(171 / 11));
        --grid-gap-width: calc(100% / 57);
    }

    .container {
        width: calc(100% - calc(var(--grid-gap-width) * 2));
    }
}

/* 6-column grid for mobile */
@media (max-width: 768px) {
    body {
        --grid-column-width: calc(100% / calc(87 / 11));
        --grid-gap-width: calc(100% / 29);

        --grid-width-6-col: 100%;
        --grid-width-7-col: 100%;
        --grid-width-8-col: 100%;
        --grid-width-9-col: 100%;
        --grid-width-10-col: 100%;
        --grid-width-11-col: 100%;
        --grid-width-12-col: 100%;
    }
}
