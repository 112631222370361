.section-01 {
    overflow: hidden;
    background-color: var(--color-neon-indigo);
}

.section-01 .thought-bubble-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .section-01 .thought-bubble-wrapper {
        align-items: flex-start;
    }
}

/* Trend 01 - Diverse Listener */

.section-01 .trend-01-diverse-listener {
    margin-top: 200px;
    display: flex;
    color: var(--color-neutral-black);
}

.section-01 .trend-01-diverse-listener .left-col {
    width: calc(var(--grid-offset-1-col) * 4);
    margin-left: var(--grid-offset-1-col);
    margin-right: calc(var(--grid-offset-1-col) * -1);
}

.section-01 .trend-01-diverse-listener .right-col {
    width: var(--grid-width-8-col);
    display: flex;
}

.section-01 .trend-01-diverse-listener .left-graph {
    margin-top: 248px;
    margin-right: var(--grid-gap-width);
}

.section-01 .trend-01-diverse-listener .left-graph .header,
.section-01 .trend-01-diverse-listener .left-graph .header-background,
.section-01 .trend-01-diverse-listener .right-graph .header,
.section-01 .trend-01-diverse-listener .right-graph .header-background {
    width: 360px;
    height: 44px;
    padding: 18px 20px;
    border: 1px solid var(--color-neutral-black);
}

.section-01 .trend-01-diverse-listener .left-graph .header,
.section-01 .trend-01-diverse-listener .right-graph .header {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
}

.section-01 .trend-01-diverse-listener .left-graph .header {
    display: flex;
    justify-content: center;
    background-color: var(--color-neon-indigo);
}

.section-01 .trend-01-diverse-listener .right-graph .header {
    margin-left: 24px;
    margin-top: -60px;
    background-color: var(--color-neon-plum);
}

.section-01 .trend-01-diverse-listener .left-graph .header-background {
    background: linear-gradient(98.62deg, var(--color-neon-plum) 43.42%, var(--color-solid-plum) 111.81%);
    margin-left: 24px;
    margin-bottom: -100px;
}

.section-01 .trend-01-diverse-listener .right-graph .header-background {
    background: linear-gradient(264.2deg, var(--color-neon-plum) -21.31%, var(--color-solid-plum) 100%);
}

.section-01 .trend-01-diverse-listener .left-graph .table {
    background-color: var(--color-neon-indigo);
}

.section-01 .trend-01-diverse-listener .table .row {
    display: flex;
    border: 1px solid var(--color-neutral-black);
    background-color: var(--color-neon-plum);
    transition: 0.5s opacity;
}

.section-01 .trend-01-diverse-listener .row:not(:first-child) {
    margin-top: -1px;
}

.section-01 .trend-01-diverse-listener .table .row:not(:last-child) {
    border-bottom: 1px solid var(--color-neutral-black);
}

.section-01 .trend-01-diverse-listener .table .row > * {
    padding: 8px 16px;
}

.section-01 .trend-01-diverse-listener .table .row > *:not(:last-child) {
    border-right: 1px solid var(--color-neutral-black);
}

.section-01 .trend-01-diverse-listener .table .col-50 {
    width: 50%;
}

.section-01 .trend-01-diverse-listener .table .col-30 {
    width: 30%;
}

.section-01 .trend-01-diverse-listener .left-graph .table .col-30 {
    background-color: var(--color-neon-plum);
}

.section-01 .trend-01-diverse-listener .right-graph .table .col-30 {
    background-color: var(--color-neon-indigo);
}

.section-01 .trend-01-diverse-listener .table .col-70 {
    width: 70%;
}

.section-01 .trend-01-diverse-listener .table .stat {
    margin-bottom: 1px;
}

.section-01 .trend-01-diverse-listener .table .caption {
    text-transform: uppercase;
}

.section-01 .trend-01-diverse-listener .table .row:nth-child(3) .col-70 {
    display: flex;
}

.section-01 .trend-01-diverse-listener .table .row:nth-child(3) .col-70 > *:first-child {
    margin-right: 32px;
}

.section-01 .trend-01-diverse-listener .left-graph .footnote {
    margin-top: 9px;
    transition: 0.3s opacity;
}

.section-01 .trend-01-diverse-listener .right-graph {
    margin-left: 24px;
}

@media (max-width: 1368px) {
    .section-01 .trend-01-diverse-listener {
        flex-direction: column;
    }

    .section-01 .trend-01-diverse-listener .left-col {
        width: var(--grid-width-11-col);
        margin-bottom: 48px;
        margin-right: 0;
    }

    .section-01 .trend-01-diverse-listener .right-col {
        width: 100%;
        margin-left: var(--grid-offset-1-col);
    }

    .section-01 .trend-01-diverse-listener .left-graph {
        width: var(--grid-width-5-col);
        margin-top: 60px;
        margin-right: var(--grid-offset-1-col);
    }

    .section-01 .trend-01-diverse-listener .right-graph {
        width: var(--grid-width-5-col);
    }

    .section-01 .trend-01-diverse-listener .left-graph .header,
    .section-01 .trend-01-diverse-listener .left-graph .header-background,
    .section-01 .trend-01-diverse-listener .right-graph .header,
    .section-01 .trend-01-diverse-listener .right-graph .header-background {
        width: calc(100% - 40px - 24px - 2px);
    }
}

@media (max-width: 1020px) {
    .section-01 .trend-01-diverse-listener .table .row:nth-child(3) .col-70 > *:first-child {
        margin-right: 16px;
    }
}

@media (max-width: 920px) {
    .section-01 .trend-01-diverse-listener .left-col {
        margin-bottom: 72px;
    }

    .section-01 .trend-01-diverse-listener .right-col {
        flex-direction: column;
    }

    .section-01 .trend-01-diverse-listener .left-graph,
    .section-01 .trend-01-diverse-listener .right-graph {
        width: var(--grid-width-11-col);
    }

    .section-01 .trend-01-diverse-listener .left-graph {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 72px;
    }

    .section-01 .trend-01-diverse-listener .right-graph {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .section-01 .trend-01-diverse-listener .left-col,
    .section-01 .trend-01-diverse-listener .right-col {
        margin-left: 0;
    }

    .section-01 .trend-01-diverse-listener .left-col {
        font-size: 50px;
        line-height: 48px;
    }
}

@media (max-width: 415px) {
    .section-01 .trend-01-diverse-listener .table .stat {
        font-size: 36px;
        line-height: 40px;
    }

    .section-01 .trend-01-diverse-listener .table .label {
        font-size: 22px;
        line-height: 24px;
    }

    .section-01 .trend-01-diverse-listener .left-graph .header,
    .section-01 .trend-01-diverse-listener .right-graph .header {
        font-size: 24px;
        line-height: 26px;
    }

    .section-01 .trend-01-diverse-listener .left-graph .header,
    .section-01 .trend-01-diverse-listener .left-graph .header-background,
    .section-01 .trend-01-diverse-listener .right-graph .header,
    .section-01 .trend-01-diverse-listener .right-graph .header-background {
        height: 26px;
    }

    .section-01 .trend-01-diverse-listener .left-graph .header-background {
        margin-bottom: -85px;
    }

    .section-01 .trend-01-diverse-listener .right-graph .header {
        margin-top: -85px;
    }
}

/* Trend 01 - Thought Bubble */

.section-01 .trend-01-thought-bubble {
    margin-top: 160px;
    margin-bottom: 380px;
}

.section-01 .trend-01-thought-bubble .thought-bubble-footer {
    width: var(--grid-width-7-col);
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.section-01 .trend-01-thought-bubble .thought-bubble-footer .component.audio-player {
    margin-top: 10px;
}

@media (max-width: 1200px) {
    .section-01 .trend-01-thought-bubble .thought-bubble-footer {
        width: var(--grid-width-10-col);
    }
}

@media (max-width: 768px) {
    .section-01 .trend-01-thought-bubble .thought-bubble-footer {
        flex-direction: column;
    }

    .section-01 .trend-01-thought-bubble .thought-bubble-footer .audio-player-wrapper {
        display: flex;
    }
}

/* Trend 02 - Escapism FTW */

.section-01 .trend-02-escapism-ftw {
    display: flex;
    color: var(--color-neutral-black);
}

.section-01 .trend-02-escapism-ftw .left-col {
    margin-left: var(--grid-offset-1-col);
    margin-right: 80px;
}

.section-01 .trend-02-escapism-ftw .right-col {
    width: var(--grid-width-7-col);
    display: flex;
}

.section-01 .trend-02-escapism-ftw .right-col .left-image {
    margin-right: 24px;
    width: 368px;
    flex-shrink: 0;
}

.section-01 .trend-02-escapism-ftw .right-col .left-image .footnote {
    margin-top: 8px;
    color: var(--color-neutral-black);
}

.section-01 .trend-02-escapism-ftw .right-col .right-image {
    width: 384px;
    flex-shrink: 0;
}

.section-01 .trend-02-escapism-ftw .right-col img {
    width: 100%;
}

.section-01 .trend-02-escapism-ftw .col-chart .header {
    margin-bottom: 24px;
    position: relative;
    height: 100px;
    width: 100%;
}

.section-01 .trend-02-escapism-ftw .col-chart .header .top-layer,
.section-01 .trend-02-escapism-ftw .col-chart .header .bottom-layer {
    position: absolute;
    border: 1px solid var(--color-neutral-black);
    width: calc(100% - 22px);
    height: 78px;
}

.section-01 .trend-02-escapism-ftw .col-chart .header .top-layer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-01 .trend-02-escapism-ftw .col-chart.left-side .header .top-layer {
    background-color: var(--color-neon-indigo);
}

.section-01 .trend-02-escapism-ftw .col-chart.right-side .header .top-layer {
    background-color: var(--color-neon-plum);
    top: 20px;
    left: 20px;
}

.section-01 .trend-02-escapism-ftw .col-chart.left-side .header .bottom-layer {
    background: linear-gradient(
        98.62deg,
        var(--color-neon-plum) 43.42%,
        var(--color-solid-plum) 111.81%
    );
    top: 20px;
    left: 20px;
}

.section-01 .trend-02-escapism-ftw .col-chart.right-side .header .bottom-layer {
    background: linear-gradient(
        264.2deg,
        var(--color-neon-plum) -21.31%,
        var(--color-solid-plum) 100%
    );
}

.section-01 .trend-02-escapism-ftw .col-chart .header-title {
    text-transform: uppercase;
    color: var(--color-neutral-black);
}

.section-01 .trend-02-escapism-ftw .col-chart .chart-rows {
    position: relative;
    border: 1px solid var(--color-neutral-black);
}

.section-01 .trend-02-escapism-ftw .col-chart.left-side .chart-rows {
    background: linear-gradient(
        98.62deg,
        var(--color-neon-plum) 43.42%,
        var(--color-solid-plum) 111.81%
    );
}

.section-01 .trend-02-escapism-ftw .col-chart.right-side .chart-rows {
    background: linear-gradient(
        160.31deg,
        var(--color-neon-plum) 24.72%,
        var(--color-solid-plum) 73.91%
    );
}

.section-01 .trend-02-escapism-ftw .col-chart .row {
    border: 1px solid var(--color-neutral-black);
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: calc(100% - 40px);
    color: var(--color-neutral-black);
    transition: 0.5s top, 0.5s left;
}

.section-01 .trend-02-escapism-ftw .col-chart.left-side .row {
    background-color: var(--color-neon-indigo);
}

.section-01 .trend-02-escapism-ftw .col-chart.right-side .row {
    background-color: var(--color-neon-plum);
}

.section-01 .trend-02-escapism-ftw .col-chart .row:not(:first-child) {
    margin-top: -1px;
}

.section-01 .trend-02-escapism-ftw .col-chart .row .arrow.up {
    margin-top: 10px;
}

.section-01 .trend-02-escapism-ftw .col-chart .row .arrow.down {
    margin-top: 2px;
    margin-right: -9px;
}

@media (max-width: 1368px) {
    .section-01 .trend-02-escapism-ftw {
        flex-direction: column;
    }

    .section-01 .trend-02-escapism-ftw .left-col {
        width: var(--grid-width-11-col);
        margin-right: 0;
        margin-bottom: 72px;
    }

    .section-01 .trend-02-escapism-ftw .right-col {
        margin-left: var(--grid-offset-1-col);
    }
}

@media (max-width: 880px) {
    .section-01 .trend-02-escapism-ftw .right-col {
        width: auto;
    }

    .section-01 .trend-02-escapism-ftw .right-col .left-image {
        width: 310px;
    }

    .section-01 .trend-02-escapism-ftw .right-col .right-image {
        width: 323px;
    }
}

@media (max-width: 768px) {
    .section-01 .trend-02-escapism-ftw .left-col,
    .section-01 .trend-02-escapism-ftw .right-col {
        margin-left: 0;
    }

    .section-01 .trend-02-escapism-ftw .left-col .title {
        font-size: 50px;
        line-height: 48px;
    }

    .section-01 .trend-02-escapism-ftw .right-col {
        flex-direction: column;
    }

    .section-01 .trend-02-escapism-ftw .right-col .left-image {
        margin-right: 0;
        width: 100%;
        margin-bottom: 60px;
    }

    .section-01 .trend-02-escapism-ftw .right-col .right-image {
        width: 100%;
    }
}

/* Trend 03 - Playing the Field */

.section-01 .trend-03-playing-the-field {
    display: flex;
    justify-content: space-between;
}

.section-01 .trend-03-playing-the-field .left-col {
    width: var(--grid-width-4-col);
    margin-left: var(--grid-offset-1-col);
}

.section-01 .trend-03-playing-the-field .right-col {
    width: var(--grid-width-6-col);
    margin-top: 96px;
}

.section-01 .trend-03-playing-the-field .right-col img {
    width: 100%;
    max-width: 535px;
    margin-bottom: 24px;
}

.section-01 .trend-03-playing-the-field .right-col .stat-value,
.section-01 .trend-03-playing-the-field .right-col .stat-caption {
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    .section-01 .trend-03-playing-the-field {
        flex-direction: column;
    }

    .section-01 .trend-03-playing-the-field .left-col {
        margin-left: 0;
        width: var(--grid-width-6-col);
    }

    .section-01 .trend-03-playing-the-field .left-col .title {
        font-size: 50px;
        line-height: 48px;
    }

    .section-01 .trend-03-playing-the-field .left-col .title br {
        display: none;
    }

    .section-01 .trend-03-playing-the-field .right-col .stat-value {
        font-size: 168px;
        line-height: 168px;
    }

    .section-01 .trend-03-playing-the-field .right-col .stat-caption {
        font-size: 21px;
        line-height: 28px;
    }
}

/* Trend 04 - Welcome Advertisers */

.section-01 .trend-04-advertisers-welcome {
    display: flex;
    justify-content: space-between;
    color: var(--color-neutral-black);
}

.section-01 .trend-04-advertisers-welcome > .left-col,
.section-01 .trend-04-advertisers-welcome > .right-col {
    width: var(--grid-width-5-col);
}

.section-01 .trend-04-advertisers-welcome > .left-col {
    margin-left: var(--grid-offset-1-col);
}

.section-01 .trend-04-advertisers-welcome > .right-col {
    margin-top: 293px;
}

.section-01 .trend-04-advertisers-welcome > .right-col > .row {
    width: 100%;
    display: flex;
}

.section-01 .trend-04-advertisers-welcome > .left-col > .title {
    margin-bottom: 40px;
}

.section-01 .trend-04-advertisers-welcome > .left-col > img {
    width: 100%;
    max-width: 535px;
    margin-bottom: 24px;
}

.section-01 .trend-04-advertisers-welcome > .left-col > .stat-value {
    margin-bottom: 40px;
}

@media (max-width: 1200px) {
    .section-01 .trend-04-advertisers-welcome > .right-col > .row:nth-child(2) {
        margin-top: 48px;
        justify-content: flex-start;
    }
}

@media (max-width: 1040px) {
    .section-01 .trend-04-advertisers-welcome {
        flex-direction: column;
    }

    .section-01 .trend-04-advertisers-welcome > .left-col,
    .section-01 .trend-04-advertisers-welcome > .right-col {
        width: var(--grid-width-11-col);
    }

    .section-01 .trend-04-advertisers-welcome > .right-col {
        margin-left: var(--grid-offset-1-col);
        margin-top: 72px;
    }
}

@media (max-width: 768px) {
    .section-01 .trend-04-advertisers-welcome > .left-col,
    .section-01 .trend-04-advertisers-welcome > .right-col {
        margin-left: 0;
    }

    .section-01 .trend-04-advertisers-welcome > .left-col .title {
        font-size: 50px;
        line-height: 48px;
    }

    .section-01 .trend-04-advertisers-welcome > .left-col .stat-value {
        font-size: 168px;
        line-height: 168px;
    }

    .section-01 .trend-04-advertisers-welcome > .left-col .stat-caption {
        font-size: 21px;
        line-height: 28px;
    }
}

/* Trend 04 - Thought Bubble */

.trend-04-thought-bubble .audio-player-wrapper {
    display: flex;
    width: var(--grid-width-7-col);
    justify-content: flex-end;
    margin-top: 24px;
}

@media (max-width: 1200px) {
    .trend-04-thought-bubble .audio-player-wrapper {
        width: var(--grid-width-10-col);
    }
}
