@font-face {
    font-family: "GT America Compressed Bold";
    src: url("../fonts/GT-America-Compressed-Bold.woff");
}

@font-face {
    font-family: "GT America Extended Bold";
    src: url("../fonts/GT-America-Extended-Bold.woff");
}

@font-face {
    font-family: "GT America";
    font-weight: 800;
    src: url("../fonts/GT-America-Standard-Black.woff");
}

@font-face {
    font-family: "GT America";
    font-weight: 700;
    src: url("../fonts/GT-America-Standard-Bold.woff");
}

@font-face {
    font-family: "GT America";
    font-weight: 500;
    src: url("../fonts/GT-America-Standard-Medium.woff");
}

@font-face {
    font-family: "GT America";
    font-weight: 400;
    src: url("../fonts/GT-America-Standard-Regular.woff");
}

@font-face {
    font-family: "SF Pro Text";
    font-weight: 400;
    src: url("../fonts/SF-Pro-Text-Regular.woff");
}



.gt-america-bold-96-96 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 96px;
    line-height: 96px;
    letter-spacing: 0.01em;
}

.gt-america-bold-80-78 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 80px;
    line-height: 78px;
    letter-spacing: -0.02em;
}

.gt-america-bold-80-76 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 80px;
    line-height: 76px;
    letter-spacing: -0.01em;
}

.gt-america-bold-80-66 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 80px;
    line-height: 66px;
    letter-spacing: -0.02em;
}

.gt-america-bold-64-70 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
}

.gt-america-bold-64-68 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 64px;
    line-height: 68px;
    letter-spacing: -0.01em;
}

.gt-america-bold-56-70 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 56px;
    line-height: 70px;
}

.gt-america-bold-56-48 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 56px;
    line-height: 48px;
    letter-spacing: -0.02em;
}

.gt-america-bold-52-44 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 52px;
    line-height: 44px;
}

.gt-america-bold-48-48 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.02em;
}

.gt-america-bold-29-29 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 29px;
    line-height: 29px;
}

.gt-america-bold-28-43 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 28px;
    line-height: 43px;
}

.gt-america-bold-28-30 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
}

.gt-america-bold-24-24 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
}

.gt-america-bold-18-22 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.08em;
}

.gt-america-bold-18-15 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    letter-spacing: 0.15em;
}

.gt-america-bold-15-24 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.06em;
}

.gt-america-bold-15-21 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
}

.gt-america-bold-14-17 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.gt-america-bold-13-12 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.05em;
}

.gt-america-bold-11-13 {
    font-family: 'GT America';
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
}



.gt-america-medium-72-50 {
    font-family: 'GT America';
    font-weight: 500;
    font-size: 72px;
    line-height: 50px;
}

.gt-america-medium-56-40 {
    font-family: 'GT America';
    font-weight: 500;
    font-size: 56px;
    line-height: 40px;
}

.gt-america-medium-44-48 {
    font-family: 'GT America';
    font-weight: 500;
    font-size: 44px;
    line-height: 48px;
}

.gt-america-medium-26-30 {
    font-family: 'GT America';
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
}

.gt-america-medium-21-24 {
    font-family: 'GT America';
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
}

.gt-america-medium-21-21 {
    font-family: 'GT America';
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
}

.gt-america-medium-16-24 {
    font-family: 'GT America';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.gt-america-medium-8-21 {
    font-family: 'GT America';
    font-weight: 500;
    font-size: 8px;
    line-height: 21px;
}



.gt-america-regular-192-192 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 192px;
    line-height: 192px;
}

.gt-america-regular-168-168 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 168px;
    line-height: 168px;
}

.gt-america-regular-145-175 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 145px;
    line-height: 175px;
}

.gt-america-regular-32-40 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
}

.gt-america-regular-26-36 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
}

.gt-america-regular-26-31 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
}

.gt-america-regular-24-33 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
}

.gt-america-regular-24-31 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
}

.gt-america-regular-21-28 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
}

.gt-america-regular-21-24 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
}

.gt-america-regular-21-21 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 21px;
    line-height: 21px;
    letter-spacing: -0.02em;
}

.gt-america-regular-20-48 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 20px;
    line-height: 48px;
    letter-spacing: 0.12em;
}

.gt-america-regular-18-24 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.gt-america-regular-18-20 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}

.gt-america-regular-16-20 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.gt-america-regular-14-19 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.gt-america-regular-14-16 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.gt-america-regular-10-30 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 10px;
    line-height: 30px;
}

.gt-america-regular-8-12 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
}

.gt-america-regular-7-50 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 7px;
    line-height: 50px;
}

.gt-america-regular-7-20 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 7px;
    line-height: 20px;
}

.gt-america-regular-7-9 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 7px;
    line-height: 9px;
}

.gt-america-regular-6-10 {
    font-family: 'GT America';
    font-weight: 400;
    font-size: 6px;
    line-height: 10px;
}



.gt-america-compressed-128-136 {
    font-family: 'GT America Compressed Bold';
    font-size: 128px;
    line-height: 136px;
    letter-spacing: 0.02em;
}

.gt-america-compressed-64-68 {
    font-family: 'GT America Compressed Bold';
    font-size: 64px;
    line-height: 68px;
}
