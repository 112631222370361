.sidebar {
    position: fixed;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5;
    pointer-events: none;
    transition: 0.3s opacity;
}

.sidebar .container {
    height: 100%;
    display: flex;
    align-items: center;
}

.sidebar .sections {
    pointer-events: auto;
}

.sidebar .sections .section:not(:last-child) {
    margin-bottom: 16px;
}

.sidebar .sections .section.inactive {
    cursor: pointer;
}

.sidebar .sections .section.active .section-title {
    color: var(--color-neutral-white);
    margin-bottom: 16px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

.sidebar .sections .section .square {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    transition: 0.3s border-color;
}

.sidebar .sections .section.active .square {
    border-color: var(--color-neutral-black);
}

.sidebar .sections .section.active .square {
    border-color: var(--color-neutral-white);
}

.sidebar .sections .section .inner-square {
    width: 8px;
    height: 8px;
    background-color: transparent;
    transition: 0.3s background-color;
}

.sidebar .sections .section.active .inner-square {
    background-color: var(--color-neutral-white);
}
