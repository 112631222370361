.section-02 {
    overflow: hidden;
    background-color: var(--color-neon-peach);
}

.section-02 .thought-bubble-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-02 .audio-player-wrapper {
    display: flex;
    width: var(--grid-width-7-col);
    justify-content: flex-end;
    margin-top: 24px;
}

@media (max-width: 1200px) {
    .section-02 .audio-player-wrapper {
        width: var(--grid-width-10-col);
    }
}

/* Chart Rows */

.section-02 .chart-rows .component.thought-bubble {
    width: 100% !important;
    margin-bottom: 10px;
}

.section-02 .chart-rows .component.thought-bubble .row {
    width: 100%;
}

.section-02 .chart-rows .row {
    display: flex;
    justify-content: space-between;
    color: var(--color-neutral-black);
}

.section-02 .chart-rows > .row {
    border: 1px solid var(--color-neutral-black);
}

.section-02 .chart-rows  > .row:not(:last-child) {
    margin-bottom: 8px;
}

.section-02 .chart-rows .row .row-left-col {
    padding: 16px;
}

.section-02 .chart-rows .component.thought-bubble .row .row-right-col {
    padding: 17px 36px 28px 36px;
}

.section-02 .chart-rows > .row .row-right-col {
    padding: 7px 16px 6px 16px;
}

.section-02 .chart-rows .row .stat {
    margin-bottom: 12px;
    display: flex;
}

.section-02 .chart-rows .row .percentage {
    margin-right: 12px;
}

.section-02 .chart-rows .row:first-child .row-left-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-02 .chart-rows .row:first-child .row-right-col {
    width: 146px;
}

.section-02 .chart-rows .row:not(:first-child) .row-right-col {
    width: 97px;
}

.section-02 .chart-rows .row img {
    width: 100%;
}

@media (max-width: 1040px) {
    .section-02 .chart-rows > .row {
        max-width: 500px;
        width: 100%;
    }

    .section-02 .chart-rows .component.thought-bubble {
        max-width: 500px;
    }
}

@media (max-width: 465px) {
    .section-02 .chart-rows .component.thought-bubble .row .percentage {
        font-size: 50px;
        line-height: 42px;
    }

    .section-02 .chart-rows > .row .percentage {
        font-size: 40px;
        line-height: 32px;
    }

    .section-02 .chart-rows .row .title {
        font-size: 16px;
        line-height: 18px;
        width: 140px;
    }

    .section-02 .chart-rows .row:not(:first-child) .row-right-col {
        width: 88px;
    }

    .section-02 .chart-rows .component.thought-bubble .row .row-right-col {
        padding: 7px 16px 6px 16px;
    }
}

/* Trend 01 - Independents Keep on Top */

.section-02 .trend-01-independents-keep-on-top {
    display: flex;
    color: var(--color-neutral-black);
}

.section-02 .trend-01-independents-keep-on-top .left-col {
    margin-left: var(--grid-offset-1-col);
    margin-right: -224px;
}

.section-02 .trend-01-independents-keep-on-top .left-col .title {
    margin-bottom: 48px;
    width: 560px;
}

.section-02 .trend-01-independents-keep-on-top .left-col .description {
    margin-bottom: 16px;
    width: 500px;
}

.section-02 .trend-01-independents-keep-on-top .right-col {
    width: var(--grid-width-7-col);
    margin-right: var(--grid-offset-1-col);
    margin-top: 96px;
}

.section-02 .trend-01-independents-keep-on-top .right-col img {
    width: 100%;
}

@media (max-width: 992px) {
    .section-02 .trend-01-independents-keep-on-top {
        justify-content: space-between;
    }

    .section-02 .trend-01-independents-keep-on-top .left-col {
        width: var(--grid-width-5-col);
    }

    .section-02 .trend-01-independents-keep-on-top .left-col .title,
    .section-02 .trend-01-independents-keep-on-top .left-col .description,
    .section-02 .trend-01-independents-keep-on-top .left-col .footnote {
        width: auto;
    }

    .section-02 .trend-01-independents-keep-on-top .left-col .title {
        font-size: 50px;
        line-height: 48px;
        margin-bottom: 24px;
    }

    .section-02 .trend-01-independents-keep-on-top .left-col .description {
        font-size: 21px;
        line-height: 28px;
    }
}

@media (max-width: 768px) {
    .section-02 .trend-01-independents-keep-on-top {
        flex-direction: column;
    }

    .section-02 .trend-01-independents-keep-on-top .left-col {
        margin-left: 0;
        width: 100%;
    }

    .section-02 .trend-01-independents-keep-on-top .right-col {
        margin-top: 40px;
    }
}

/* Trend 02 - Murdering the Charts */

.section-02 .trend-02-murdering-the-charts {
    display: flex;
    justify-content: space-between;
    color: var(--color-neutral-black);
}

.section-02 .trend-02-murdering-the-charts .left-col {
    margin-left: var(--grid-offset-1-col);
}

.section-02 .trend-02-murdering-the-charts .left-col .title {
    margin-bottom: 48px;
    width: 410px;
}

.section-02 .trend-02-murdering-the-charts .left-col .description {
    margin-bottom: 16px;
    width: 410px;
}

.section-02 .trend-02-murdering-the-charts .right-col {
    width: var(--grid-width-5-col);
    margin-right: var(--grid-offset-1-col);
}

.section-02 .trend-02-murdering-the-charts .right-col img {
    width: 100%;
}

@media (max-width: 1040px) {
    .section-02 .trend-02-murdering-the-charts {
        flex-direction: column;
    }

    .section-02 .trend-02-murdering-the-charts .left-col {
        margin-bottom: 80px;
    }

    .section-02 .trend-02-murdering-the-charts .left-col .title {
        width: 100%;
        margin-bottom: 24px;
    }

    .section-02 .trend-02-murdering-the-charts .left-col .description {
        width: 100%;
    }

    .section-02 .trend-02-murdering-the-charts .right-col {
        margin-left: var(--grid-offset-1-col);
        margin-right: 0;
        width: auto !important;
    }
}

@media (max-width: 910px) {
    .section-02 .trend-02-murdering-the-charts .left-col .title {
        font-size: 50px;
        line-height: 48px;
    }

    .section-02 .trend-02-murdering-the-charts .left-col .description {
        font-size: 21px;
        line-height: 28px;
    }
}

@media (max-width: 768px) {
    .section-02 .trend-02-murdering-the-charts .left-col {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 40px;
    }

    .section-02 .trend-02-murdering-the-charts .right-col {
        width: 100%;
        margin-left: 0;
    }

    .section-02 .trend-02-murdering-the-charts .right-col img {
        max-width: 400px;
    }
}

/* Trend 02 - Niche is Mainstream */

.section-02 .trend-02-niche-is-mainstream {
    display: flex;
    justify-content: space-between;
    color: var(--color-neutral-black);
}

.section-02 .trend-02-niche-is-mainstream .left-col {
    margin-left: var(--grid-offset-1-col);
}

.section-02 .trend-02-niche-is-mainstream .left-col .title {
    margin-bottom: 48px;
    width: 480px;
}

.section-02 .trend-02-niche-is-mainstream .left-col .description {
    margin-bottom: 16px;
    width: 480px;
}

.section-02 .trend-02-niche-is-mainstream .right-col {
    width: var(--grid-width-5-col);
    margin-right: var(--grid-offset-1-col);
}

.section-02 .trend-02-niche-is-mainstream .right-col img {
    width: 100%;
}

@media (max-width: 1040px) {
    .section-02 .trend-02-niche-is-mainstream {
        flex-direction: column;
    }

    .section-02 .trend-02-niche-is-mainstream .left-col {
        margin-bottom: 80px;
    }

    .section-02 .trend-02-niche-is-mainstream .left-col .title {
        width: 100%;
        margin-bottom: 24px;
    }

    .section-02 .trend-02-niche-is-mainstream .left-col .description {
        width: 100%;
    }

    .section-02 .trend-02-niche-is-mainstream .right-col {
        margin-left: var(--grid-offset-1-col);
        margin-right: 0;
        width: auto !important;
    }
}

@media (max-width: 910px) {
    .section-02 .trend-02-niche-is-mainstream .left-col .title {
        font-size: 50px;
        line-height: 48px;
    }

    .section-02 .trend-02-niche-is-mainstream .left-col .description {
        font-size: 21px;
        line-height: 28px;
    }
}

@media (max-width: 768px) {
    .section-02 .trend-02-niche-is-mainstream .left-col {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 40px;
    }

    .section-02 .trend-02-niche-is-mainstream .right-col {
        width: 100%;
        margin-left: 0;
    }

    .section-02 .trend-02-niche-is-mainstream .right-col img {
        max-width: 400px;
    }
}

/* Trend 03 - What's Good, Hollywood? 1 */

.section-02 .trend-03-whats-good-hollywood-1 {
    display: flex;
    justify-content: space-between;
    color: var(--color-neutral-black);
    position: relative;
    height: 810px;
}

.section-02 .trend-03-whats-good-hollywood-1 .title {
    width: 500px;
    margin-left: var(--grid-offset-1-col);
}

.section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack,
.section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack img {
    width: 180px;
}

.section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack {
    position: absolute;
    right: var(--grid-offset-1-col);
}

.section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack img {
    position: absolute;
    transition: 1.5s left, 1.5s top;
}

@media (max-width: 1175px) {
    .section-02 .trend-03-whats-good-hollywood-1 {
        justify-content: flex-end;
    }

    .section-02 .trend-03-whats-good-hollywood-1 .title {
        position: absolute;
        left: 0;
    }
}

@media (max-width: 930px) {
    .section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack,
    .section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack img {
        width: 120px;
    }
}

@media (max-width: 860px) {
    .section-02 .trend-03-whats-good-hollywood-1 .title {
        font-size: 50px;
        line-height: 48px;
    }
}

@media (max-width: 768px) {
    .section-02 .trend-03-whats-good-hollywood-1 .title {
        margin-left: 0;
    }

    .section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack {
        right: 0;
    }
}

@media (max-width: 540px) {
    .section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack,
    .section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack img {
        width: 180px;
    }

    .section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack {
        right: -150px;
    }
}

@media (max-width: 420px) {
    .section-02 .trend-03-whats-good-hollywood-1 .cover-art-stack {
        right: -250px;
    }
}

/* Trend 03 - What's Good, Hollywood? 2 */

.section-02 .trend-03-whats-good-hollywood-2 {
    display: flex;
    justify-content: flex-end;
    color: var(--color-neutral-black);
    position: relative;
}

.section-02 .trend-03-whats-good-hollywood-2 .left-col {
    position: absolute;
    left: var(--grid-offset-1-col);
}

.section-02 .trend-03-whats-good-hollywood-2 .left-col .title {
    width: 500px;
    margin-bottom: 48px;
}

.section-02 .trend-03-whats-good-hollywood-2 .left-col .description {
    width: 350px;
    margin-bottom: 16px;
}

.section-02 .trend-03-whats-good-hollywood-2 .right-col {
    display: flex;
    align-items: flex-end;
}

.section-02 .trend-03-whats-good-hollywood-2 .right-col img:first-child {
    margin-bottom: 35px;
    margin-right: 40px;
}

@media (max-width: 1320px) {
    .section-02 .trend-03-whats-good-hollywood-2 .right-col {
        margin-top: 60px;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .right-col img:first-child {
        margin-bottom: 26px;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .right-col img:nth-child(2) {
        width: 500px;
    }
}

@media (max-width: 1090px) {
    .section-02 .trend-03-whats-good-hollywood-2 .right-col {
        margin-top: 160px;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .right-col img:first-child {
        width: 160px;
        margin-bottom: 20px;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .right-col img:nth-child(2) {
        width: 400px;
    }
}

@media (max-width: 840px) {
    .section-02 .trend-03-whats-good-hollywood-2 .right-col img:first-child {
        margin-bottom: 17px;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .right-col img:nth-child(2) {
        width: 350px;
    }
}

@media (max-width: 768px) {
    .section-02 .trend-03-whats-good-hollywood-2 .left-col .title {
        font-size: 50px;
        line-height: 48px;
        margin-bottom: 24px;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .left-col .description {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .left-col .title,
    .section-02 .trend-03-whats-good-hollywood-2 .left-col .description {
        width: auto;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .right-col {
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-top: 240px;
    }

    .section-02 .trend-03-whats-good-hollywood-2 .right-col img:first-child {
        position: relative;
        margin-right: 0;
        margin-bottom: -100px;
    }
}

@media (max-width: 460px) {
    .section-02 .trend-03-whats-good-hollywood-2 .left-col {
        left: 0;
    }
}

@media (max-width: 332px) {
    .section-02 .trend-03-whats-good-hollywood-2 .right-col {
        margin-top: 280px;
    }
}

/* Trend 04 - Show Me the Money */

.section-02 .trend-04-show-me-the-money {
    display: flex;
    justify-content: space-between;
    color: var(--color-neutral-black);
}

.section-02 .trend-04-show-me-the-money .left-col {
    margin-left: var(--grid-offset-1-col);
}

.section-02 .trend-04-show-me-the-money .left-col .title {
    margin-bottom: 48px;
    width: 500px;
}

.section-02 .trend-04-show-me-the-money .left-col .description {
    margin-bottom: 16px;
    width: 380px;
}

.section-02 .trend-04-show-me-the-money .right-col {
    width: var(--grid-width-5-col);
}

.section-02 .trend-04-show-me-the-money .right-col img {
    width: 100%;
}

.section-02 .trend-04-show-me-the-money .fade-in-group {
    transition: 1s opacity;
}

.section-02 .bar-chart-row {
    transition: 1s opacity;
}

.section-02 .bar-chart-row:not(:last-child) {
    margin-bottom: 4px;
}

.section-02 .bar-chart-row .title-row {
    display: flex;
}

.section-02 .bar-chart-row .title {
    padding: 4px;
    background-color: var(--color-neutral-black);
    color: var(--color-neon-peach);
    text-transform: uppercase;
}

.section-02 .bar-chart-row .graph-and-value-row {
    display: flex;
}

.section-02 .bar-chart-row .graph {
    border: 1px solid var(--color-neutral-black);
    width: 325px;
    height: 70px;
    margin-right: 24px;
}

.section-02 .bar-chart-row .progress {
    background: var(--gradient-rose-peach-2);
    border-right: 1px solid var(--color-neutral-black);
    height: 100%;
    transition: 1s width;
}

.section-02 .bar-chart-row .value {
    width: 137px;
    flex-shrink: 0;
}

@media (max-width: 1040px) {
    .section-02 .trend-04-show-me-the-money .right-col {
        margin-right: 0;
    }
}

@media (max-width: 910px) {
    .section-02 .trend-04-show-me-the-money .left-col .title {
        font-size: 50px;
        line-height: 48px;
        margin-bottom: 24px;
    }

    .section-02 .trend-04-show-me-the-money .left-col .description {
        font-size: 21px;
        line-height: 28px;
    }

    .section-02 .trend-04-show-me-the-money .left-col {
        margin-right: var(--grid-offset-1-col);
    }

    .section-02 .trend-04-show-me-the-money .left-col .title,
    .section-02 .trend-04-show-me-the-money .left-col .description {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .section-02 .trend-04-show-me-the-money {
        flex-direction: column;
    }

    .section-02 .trend-04-show-me-the-money .left-col {
        margin-left: 0;
        margin-right: 0;
    }

    .section-02 .trend-04-show-me-the-money .right-col {
        margin-top: 40px;
        width: 100%;
    }

    .section-02 .trend-04-show-me-the-money .right-col img {
        max-width: 400px;
    }
}

@media (max-width: 450px) {
    .section-02 .bar-chart-row .graph {
        width: 248px;
        height: 56px;
    }

    .section-02 .bar-chart-row .value {
        font-size: 54px;
        line-height: 56px;
        width: 115px;
    }
}

/* Trend 04 - Thought Bubble */

.trend-04-thought-bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trend-04-thought-bubble .right-col {
    position: relative;
}

.trend-04-thought-bubble .play-button-wrapper {
    position: absolute;
    left: -86px;
    bottom: 90px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .trend-04-thought-bubble .left-col {
        margin-bottom: 72px !important;
    }

    .trend-04-thought-bubble .play-button-wrapper {
        top: -54px;
        left: auto;
        right: -2px;
        bottom: auto;
    }
}
