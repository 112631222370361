body {
    /* Neutral Colors */

    --color-neutral-white: #FFFFFF;
    --color-neutral-black: #000000;
    --color-neutral-gray: #F2F2F2;
    --color-neutral-dark-gray: #A1A1A1;
    --color-neutral-dark-gray-2: #404040;

    /* Neon Colors */

    --color-neon-indigo: #9771DB;
    --color-neon-plum: #C17DD4;
    --color-neon-peach: #FF645A;
    --color-neon-tangerine: #FB6B2B;

    /* Solid Colors */

    --color-solid-bright-peach: #ED8A7C;
    --color-solid-tangerine: #F4901C;
    --color-solid-indigo: #313186;
    --color-solid-plum: #A846AF;
    --color-solid-stitcher-blue: #005BAA;
    --color-solid-sxm-blue: #3391C4;
    --color-solid-rose: #E7556D;
    --color-solid-gold: #ECB43F;
    --color-solid-purple: #A844AF;

    /* Semi-Transparent Colors */

    --color-transparent: rgba(0, 0, 0, 0);
    --color-neutral-black-20t: rgba(0, 0, 0, 20%);
    --color-neutral-dark-gray-20t: rgba(67, 67, 67, 20%);
    --color-neutral-dark-gray-40t: rgba(68, 68, 68, 40%);
    --color-neon-plum-20t: rgba(193, 125, 212, 20%);
    --color-solid-rose-20t: rgba(231, 85, 109, 20%);
    --color-solid-stitcher-blue-20t: rgba(0, 91, 170, 20%);


    /* Gradients */

    --gradient-neon-plum-only: linear-gradient(180deg, var(--color-transparent) 0%, var(--color-neon-plum) 100%);
    --gradient-neon-indigo-only: linear-gradient(180deg, var(--color-transparent) 0%, var(--color-neon-indigo) 100%);
    --gradient-neon-peach-only: linear-gradient(180deg, var(--color-transparent) 0%, var(--color-neon-peach) 100%);
    --gradient-neon-tangerine-only: linear-gradient(180deg, var(--color-transparent) 0%, var(--color-neon-tangerine) 100%);
    --gradient-blue-blue: linear-gradient(180deg, var(--color-solid-sxm-blue) 0%, var(--color-solid-stitcher-blue) 100%);
    --gradient-indigo-plum: linear-gradient(180deg, var(--color-solid-plum) 0%, var(--color-solid-indigo) 100%);
    --gradient-rose-peach: linear-gradient(180deg, var(--color-solid-bright-peach) 0%, var(--color-solid-rose) 100%);
    --gradient-tangerine-gold: linear-gradient(180deg, var(--color-solid-gold) 0%, var(--color-solid-tangerine) 100%);
    --gradient-tangerine-neon-tangerine: linear-gradient(180deg, var(--color-solid-tangerine) 0%, var(--color-neon-tangerine) 100%);
    --gradient-plum-plum: linear-gradient(180deg, var(--color-neon-plum) 0%, var(--color-solid-plum) 100%);
    --gradient-rose-peach-2: linear-gradient(180deg, var(--color-neon-peach) 0%, var(--color-solid-rose) 100%);
    --gradient-indigo-indigo: linear-gradient(180deg, var(--color-neon-indigo) 0%, var(--color-solid-indigo) 100%);
    --gradient-plum-only: linear-gradient(180deg, var(--color-transparent) 0%, var(--color-neon-plum) 100%);
    --gradient-indigo-only: linear-gradient(180deg, var(--color-transparent) 0%, var(--color-neon-indigo) 100%);
    --gradient-gold-only: linear-gradient(180deg, var(--color-transparent) 0%, var(--color-solid-gold) 100%);
}
