.section-03 {
    overflow: hidden;
    background-color: var(--color-solid-sxm-blue);
}

.section-03 .thought-bubble-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-03 .audio-player-wrapper {
    display: flex;
    width: var(--grid-width-7-col);
    justify-content: flex-end;
    margin-top: 24px;
}

@media (max-width: 1200px) {
    .section-03 .audio-player-wrapper {
        width: var(--grid-width-10-col);
    }
}

/* Trend 01 - Who's Doing It Right */

.section-03 .trend-01-whos-doing-it-right {
    position: relative;
}

.section-03 .trend-01-whos-doing-it-right .left-col {
    margin-left: var(--grid-offset-1-col);
    width: 520px;
    padding-bottom: 440px;
}

.section-03 .trend-01-whos-doing-it-right .left-col .title {
    margin-bottom: 48px;
}

.section-03 .trend-01-whos-doing-it-right .left-col .description {
    margin-bottom: 16px;
}

.section-03 .trend-01-whos-doing-it-right img {
    position: absolute;
    right: -120px;
    top: -56px;
}

@media (min-width: 1369px) and (max-height: 850px) {
    .section-03 .trend-01-whos-doing-it-right img {
        width: 750px;
        right: 0;
    }
}

@media (max-width: 1368px) {
    .section-03 .trend-01-whos-doing-it-right .left-col {
        padding-bottom: 600px;
    }

    .section-03 .trend-01-whos-doing-it-right img {
        right: -60px;
        top: 170px;
    }
}

@media (max-width: 1100px) {
    .section-03 .trend-01-whos-doing-it-right {
        display: flex;
        flex-direction: column;
    }

    .section-03 .trend-01-whos-doing-it-right .left-col {
        padding-bottom: 0;
    }

    .section-03 .trend-01-whos-doing-it-right img {
        margin-left: var(--grid-offset-1-col);
        position: relative;
        top: 0;
        right: 0;
    }
}

@media (max-width: 768px) {
    .section-03 .trend-01-whos-doing-it-right {
        align-items: flex-start;
    }

    .section-03 .trend-01-whos-doing-it-right .left-col,
    .section-03 .trend-01-whos-doing-it-right img {
        margin-left: 0;
    }

    .section-03 .trend-01-whos-doing-it-right .left-col {
        width: auto;
        margin-bottom: 8px;
    }

    .section-03 .trend-01-whos-doing-it-right img {
        width: 100%;
    }

    .section-03 .trend-01-whos-doing-it-right .left-col .title {
        font-size: 50px;
        line-height: 48px;
        margin-bottom: 24px;
    }

    .section-03 .trend-01-whos-doing-it-right .left-col .description {
        font-size: 21px;
        line-height: 28px;
    }
}

/* Trend 02 - Smoothing Out Spends */

.section-03 .trend-02-smoothing-out-spends {
    display: flex;
}

.section-03 .trend-02-smoothing-out-spends .title {
    margin-left: var(--grid-offset-1-col);
    width: 560px;
}

.section-03 .trend-02-smoothing-out-spends img {
    margin-top: 48px;
}

@media (max-width: 1200px) {
    .section-03 .trend-02-smoothing-out-spends {
        flex-direction: column;
    }

    .section-03 .trend-02-smoothing-out-spends .title {
        width: auto;
    }

    .section-03 .trend-02-smoothing-out-spends img {
        margin-left: var(--grid-offset-1-col);
        max-width: 670px;
    }
}

@media (max-width: 768px) {
    .section-03 .trend-02-smoothing-out-spends {
        align-items: flex-start;
    }

    .section-03 .trend-02-smoothing-out-spends .title,
    .section-03 .trend-02-smoothing-out-spends img {
        margin-left: 0;
    }

    .section-03 .trend-02-smoothing-out-spends .title {
        font-size: 50px;
        line-height: 48px;
    }

    .section-03 .trend-02-smoothing-out-spends img {
        width: 100%;
    }
}

/* Trend 03 - Your Favorite Audiences */

.section-03 .trend-03-your-favorite-audiences > div {
    margin-left: var(--grid-offset-1-col);
    color: var(--color-neutral-black);
}

.section-03 .trend-03-your-favorite-audiences .title {
    width: 560px;
    margin-bottom: 48px;
}

.section-03 .trend-03-your-favorite-audiences .description {
    margin-bottom: 16px;
}

.section-03 .trend-03-your-favorite-audiences .footnote {
    margin-bottom: 48px;
}

.section-03 .trend-03-your-favorite-audiences .audiences {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 29px;
    row-gap: 36px;
    color: var(--color-neutral-black);
}

.section-03 .trend-03-your-favorite-audiences .audiences > .audience {
    border: 1px solid var(--color-neutral-black);
}

.section-03 .trend-03-your-favorite-audiences .audience {
    padding: 16px;
    background:
        linear-gradient(118.32deg, var(--color-solid-sxm-blue) 32.49%, var(--color-solid-stitcher-blue) 117.06%),
        linear-gradient(0deg, var(--color-solid-sxm-blue), var(--color-solid-sxm-blue));
}

.section-03 .trend-03-your-favorite-audiences .audience .number {
    margin-bottom: 16px;
}

.section-03 .trend-03-your-favorite-audiences .audience .audience-name {
    height: 40px;
    display: flex;
    align-items: flex-end;
}

.section-03 .trend-03-your-favorite-audiences .component.thought-bubble .audience {
    width: calc(100% - 32px);
}

@media (max-width: 900px) {
    .section-03 .trend-03-your-favorite-audiences .audiences {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .section-03 .trend-03-your-favorite-audiences > div {
        margin-left: 0;
    }

    .section-03 .trend-03-your-favorite-audiences .title {
        width: auto;
        font-size: 50px;
        line-height: 48px;
        margin-bottom: 24px;
    }

    .section-03 .trend-03-your-favorite-audiences .description {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    .section-03 .trend-03-your-favorite-audiences .footnote {
        margin-bottom: 72px;
    }

    .section-03 .trend-03-your-favorite-audiences img {
        max-width: 384px;
        width: 100%;
    }
}

@media (max-width: 450px) {
    .section-03 .trend-03-your-favorite-audiences .audiences {
        gap: 16px;
    }

    .section-03 .trend-03-your-favorite-audiences .audience {
        padding: 8px;
    }

    .section-03 .trend-03-your-favorite-audiences .audience .number {
        font-size: 32px;
        line-height: 32px;
    }

    .section-03 .trend-03-your-favorite-audiences .audience .audience-name {
        font-size: 18px;
        line-height: 20px;
    }

    .section-03 .trend-03-your-favorite-audiences .component.thought-bubble .audience {
        width: calc(100% - 16px);
    }
}

@media (max-width: 368px) {
    .section-03 .trend-03-your-favorite-audiences .audience .audience-name {
        height: 60px;
    }
}

/* Trend 04 - Creativity At Scale */

.section-03 .trend-04-creativity-at-scale {
    display: flex;
    justify-content: space-between;
}

.section-03 .trend-04-creativity-at-scale img {
    width: 100%;
}

.section-03 .trend-04-creativity-at-scale > .left-col {
    margin-left: var(--grid-offset-1-col);
    width: var(--grid-width-5-col);
}

.section-03 .trend-04-creativity-at-scale > .left-col > .title {
    margin-bottom: 210px;
}

.section-03 .trend-04-creativity-at-scale > .right-col {
    margin-right: var(--grid-offset-1-col);
    width: var(--grid-width-5-col);
}

.section-03 .trend-04-creativity-at-scale > .right-col img {
    margin-top: 64px;
    margin-bottom: 200px;
}

.section-03 .trend-04-creativity-at-scale > .right-col > .row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.section-03 .trend-04-creativity-at-scale .creativity-block {
    display: flex;
}

.section-03 .trend-04-creativity-at-scale .creativity-block.alternate {
    flex-direction: row-reverse;
}

.section-03 .trend-04-creativity-at-scale .creativity-block .pop-out-block {
    width: 188px;
    height: 188px;
    padding: 16px;
    display: flex;
    align-items: center;
    background: linear-gradient(118.32deg, var(--color-solid-sxm-blue) 32.49%, var(--color-solid-stitcher-blue) 117.06%);
}

.section-03 .trend-04-creativity-at-scale .creativity-block .info {
    border-top: 1px solid var(--color-neutral-black);
    padding-top: 11px;
}

.section-03 .trend-04-creativity-at-scale .creativity-block:not(.alternate) .info {
    padding-left: 14px;
}

.section-03 .trend-04-creativity-at-scale .creativity-block.alternate .info {
    margin-top: 16px;
    padding-right: 14px;
}

.section-03 .trend-04-creativity-at-scale .creativity-block .info .footnote {
    padding-left: 16px;
}

@media (max-width: 1368px) {
    .section-03 .trend-04-creativity-at-scale .left-col {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .section-03 .trend-04-creativity-at-scale > .left-col > .title,
    .section-03 .trend-04-creativity-at-scale .creativity-block.alternate {
        margin-bottom: 100px;
    }
}

@media (max-width: 950px) {
    .section-03 .trend-04-creativity-at-scale {
        flex-direction: column;
    }

    .section-03 .trend-04-creativity-at-scale > .left-col > .title {
        font-size: 50px;
        line-height: 48px;
    }

    .section-03 .trend-04-creativity-at-scale > .left-col > .title,
    .section-03 .trend-04-creativity-at-scale .creativity-block.alternate {
        margin-bottom: 60px;
    }
}

@media (max-width: 768px) {
    .section-03 .trend-04-creativity-at-scale > .left-col,
    .section-03 .trend-04-creativity-at-scale > .right-col {
        margin-left: 0;
    }
}

@media (max-width: 600px) {
    .section-03 .trend-04-creativity-at-scale .creativity-block .pop-out-block {
        width: 140px;
        height: 140px;
        padding: 8px;
    }

    .section-03 .trend-04-creativity-at-scale .creativity-block .value {
        font-size: 90px;
        line-height: 90px;
    }

    .section-03 .trend-04-creativity-at-scale .creativity-block .content {
        font-size: 14px;
        line-height: 18px;
    }

    .section-03 .trend-04-creativity-at-scale .creativity-block.alternate .info {
        padding-right: 16px;
    }

    .section-03 .trend-04-creativity-at-scale .creativity-block .info .footnote {
        padding-left: 4px;
    }
}

@media (max-width: 390px) {
    .section-03 .trend-04-creativity-at-scale .creativity-block .value {
        font-size: 72px;
        line-height: 72px;
    }

    .section-03 .trend-04-creativity-at-scale .creativity-block .content {
        font-size: 12px;
        line-height: 14px;
    }
}
