.section-04 {
    overflow: hidden;
    background-color: var(--color-solid-tangerine);
}

.section-04 .thought-bubble-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Trend 01 - The Listener Glass is Half Full */

.section-04 .trend-01-the-listener-glass-is-half-full {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.section-04 .trend-01-the-listener-glass-is-half-full .left-col {
    margin-left: var(--grid-offset-1-col);
}

.section-04 .trend-01-the-listener-glass-is-half-full .left-col .title {
    width: 480px;
    margin-bottom: 48px;
}

.section-04 .trend-01-the-listener-glass-is-half-full .left-col .description {
    width: var(--grid-width-4-col);
    margin-bottom: 16px;
}

.section-04 .trend-01-the-listener-glass-is-half-full .image-wrapper {
    width: var(--grid-width-6-col);
    margin-top: 200px;
    display: flex;
}

.section-04 .trend-01-the-listener-glass-is-half-full .image-wrapper .anim-wrapper:first-child {
    width: 53%;
}

.section-04 .trend-01-the-listener-glass-is-half-full .image-wrapper .anim-wrapper:nth-child(2) {
    width: 47%;
}

.section-04 .trend-01-the-listener-glass-is-half-full .image-wrapper .anim-wrapper img {
    width: 100%;
}

@media (max-width: 1368px) {
    .section-04 .trend-01-the-listener-glass-is-half-full .left-col {
        margin-right: var(--grid-offset-1-col);
    }

    .section-04 .trend-01-the-listener-glass-is-half-full .left-col .title,
    .section-04 .trend-01-the-listener-glass-is-half-full .left-col .description {
        width: auto;
        margin-right: 0;
    }

    .section-04 .trend-01-the-listener-glass-is-half-full .image-wrapper {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .section-04 .trend-01-the-listener-glass-is-half-full {
        flex-direction: column;
    }

    .section-04 .trend-01-the-listener-glass-is-half-full .left-col {
        margin-left: 0;
        margin-right: 0;
    }

    .section-04 .trend-01-the-listener-glass-is-half-full .left-col .title {
        font-size: 50px;
        line-height: 48px;
        margin-bottom: 40px;
    }

    .section-04 .trend-01-the-listener-glass-is-half-full .left-col .description {
        font-size: 21px;
        line-height: 28px;
    }
}

/* Trend 02 - Untapped Content */

.section-04 .trend-02-untapped-content {
    position: relative;
}

.section-04 .trend-02-untapped-content .left-col {
    margin-left: var(--grid-offset-1-col);
    padding-bottom: 375px;
}

.section-04 .trend-02-untapped-content .left-col .title {
    margin-bottom: 32px;
    width: 410px;
}

.section-04 .trend-02-untapped-content .left-col .description {
    width: 410px;
}

.section-04 .trend-02-untapped-content .image-wrapper {
    position: absolute;
    right: 0;
    top: 48px;
}

.section-04 .trend-02-untapped-content .image-wrapper .yes-value {
    position: absolute;
    right: 50px;
}

.section-04 .trend-02-untapped-content .image-wrapper .no-value {
    position: absolute;
    left: 180px;
    bottom: 0;
}

@media (max-width: 1200px) {
    .section-04 .trend-02-untapped-content .image-wrapper {
        width: 600px;
    }

    .section-04 .trend-02-untapped-content .image-wrapper img {
        width: 100%;
    }

    .section-04 .trend-02-untapped-content .image-wrapper .yes-value,
    .section-04 .trend-02-untapped-content .image-wrapper .no-value {
        font-size: 100px;
        line-height: 100px;
    }

    .section-04 .trend-02-untapped-content .image-wrapper .yes-value {
        right: 40px;
        top: 10px;
    }

    .section-04 .trend-02-untapped-content .image-wrapper .no-value {
        left: 120px;
        bottom: 10px;
    }
}

@media (max-width: 975px) {
    .section-04 .trend-02-untapped-content .left-col {
        padding-bottom: 0;
    }

    .section-04 .trend-02-untapped-content .left-col .title,
    .section-04 .trend-02-untapped-content .left-col .description {
        width: auto;
    }

    .section-04 .trend-02-untapped-content .image-wrapper {
        position: relative;
        top: 0;
        margin-left: var(--grid-offset-1-col);
    }
}

@media (max-width: 768px) {
    .section-04 .trend-02-untapped-content .left-col,
    .section-04 .trend-02-untapped-content .image-wrapper {
        margin-left: 0;
    }

    .section-04 .trend-02-untapped-content .left-col .title {
        font-size: 50px;
        line-height: 48px;
    }

    .section-04 .trend-02-untapped-content .left-col .description {
        font-size: 21px;
        line-height: 28px;
    }

    .section-04 .trend-02-untapped-content .image-wrapper {
        margin-top: 24px;
        width: 100%;
    }
}

@media (max-width: 580px) {
    .section-04 .trend-02-untapped-content .image-wrapper .yes-value,
    .section-04 .trend-02-untapped-content .image-wrapper .no-value {
        font-size: 80px;
        line-height: 80px;
    }
}

@media (max-width: 480px) {
    .section-04 .trend-02-untapped-content .image-wrapper .yes-value,
    .section-04 .trend-02-untapped-content .image-wrapper .no-value {
        font-size: 60px;
        line-height: 60px;
    }

    .section-04 .trend-02-untapped-content .image-wrapper .no-value {
        left: 80px;
    }
}

@media (max-width: 380px) {
    .section-04 .trend-02-untapped-content .image-wrapper .yes-value {
        right: 20px;
        top: 5px;
    }

    .section-04 .trend-02-untapped-content .image-wrapper .no-value {
        left: 75px;
    }
}

@media (max-width: 350px) {
    .section-04 .trend-02-untapped-content .image-wrapper .yes-value {
        right: 10px;
        top: -5px;
    }

    .section-04 .trend-02-untapped-content .image-wrapper .no-value {
        left: 65px;
        bottom: 0;
    }
}

/* Trend 03 - Your Favorite Audiences */

.section-04 .trend-03-missed-connections > div {
    margin-left: var(--grid-offset-1-col);
}

.section-04 .trend-03-missed-connections .title {
    width: 560px;
    margin-bottom: 48px;
}

.section-04 .trend-03-missed-connections .description {
    margin-bottom: 16px;
}

.section-04 .trend-03-missed-connections .footnote {
    margin-bottom: 48px;
}

.section-04 .trend-03-missed-connections .missed-connections {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 29px;
    row-gap: 36px;
    color: var(--color-neutral-black);
}

.section-04 .trend-03-missed-connections .missed-connections > .missed-connection {
    border: 1px solid var(--color-neutral-black);
}

.section-04 .trend-03-missed-connections .missed-connection {
    padding: 16px;
    background: var(--gradient-tangerine-gold);
}

.section-04 .trend-03-missed-connections .missed-connection .number {
    margin-bottom: 16px;
}

.section-04 .trend-03-missed-connections .missed-connection .connection-name {
    height: 40px;
    display: flex;
    align-items: flex-end;
}

.section-04 .trend-03-missed-connections .component.thought-bubble .missed-connection {
    width: calc(100% - 32px);
}

@media (max-width: 1132px) {
    .section-04 .trend-03-missed-connections .missed-connection .connection-name {
        height: 80px;
    }
}

@media (max-width: 900px) {
    .section-04 .trend-03-missed-connections .missed-connections {
        grid-template-columns: repeat(2, 1fr);
    }

    .section-04 .trend-03-missed-connections .missed-connection .connection-name {
        height: 40px;
    }
}

@media (max-width: 768px) {
    .section-04 .trend-03-missed-connections > div {
        margin-left: 0;
    }

    .section-04 .trend-03-missed-connections .title {
        width: auto;
        font-size: 50px;
        line-height: 48px;
        margin-bottom: 24px;
    }

    .section-04 .trend-03-missed-connections .description {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    .section-04 .trend-03-missed-connections .footnote {
        margin-bottom: 72px;
    }
}

@media (max-width: 450px) {
    .section-04 .trend-03-missed-connections .missed-connections {
        gap: 16px;
    }

    .section-04 .trend-03-missed-connections .missed-connection {
        padding: 8px;
    }

    .section-04 .trend-03-missed-connections .missed-connection .number {
        font-size: 32px;
        line-height: 32px;
    }

    .section-04 .trend-03-missed-connections .missed-connection .connection-name {
        font-size: 18px;
        line-height: 20px;
    }

    .section-04 .trend-03-missed-connections .component.thought-bubble .missed-connection {
        width: calc(100% - 16px);
    }
}

@media (max-width: 437px) {
    .section-04 .trend-03-missed-connections .missed-connection .connection-name {
        height: 60px;
    }
}

@media (max-width: 364px) {
    .section-04 .trend-03-missed-connections .missed-connection .connection-name {
        height: 80px;
    }
}
