.intro {
    position: relative;
    z-index: 2;
}

/* Jumbotron */

@keyframes floating-anim {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(10px);
    }
}

@keyframes scaling-anim {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

.intro .cover-image-wrapper {
    position: relative;
    overflow: hidden;
}

.intro .cover-image-wrapper .cover-image {
    width: 100%;
}

.intro .cover-image-wrapper .anim-image {
    position: absolute;
    width: 150px;
}

.intro .cover-image-wrapper .heart-wrapper {
    top: 145px;
    right: 95px;
    position: absolute;
    width: 150px;
}

.intro .cover-image-wrapper .anim-image.heart-3d {
    animation-duration: 2s;
    animation-name: scaling-anim;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
}

.intro .cover-image-wrapper .anim-image.microphones-3d {
    bottom: 230px;
    left: 70px;
    animation-duration: 3s;
    animation-name: floating-anim;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
}

.intro .cover-image-wrapper .anim-image.headphones-3d {
    bottom: 100px;
    right: 280px;
    animation-duration: 3.5s;
    animation-name: floating-anim;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
}

.intro .thought-bubble-wrapper {
    color: var(--color-neutral-white);
    width: 426px;
    margin-top: -250px;
}

.intro .presented-by {
    text-transform: uppercase;
    color: var(--color-neutral-white);
    text-align: center;
    margin-top: -12px;
    margin-bottom: 220px;
    position: relative;
    z-index: 2;
}

.intro .presented-by .sxm-media {
    color: var(--color-neon-indigo);
}

.intro .presented-by .adswizz {
    color: var(--color-neon-tangerine);
}

.intro .presented-by .simplecast {
    color: var(--color-neon-peach);
}

.intro .presented-by br {
    display: none;
}

@media (max-width: 1300px) {
    .intro .cover-image-wrapper .anim-image {
        width: 125px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        right: 250px;
    }
}

@media (max-height: 950px) and (min-width: 1200px) {
    .intro .cover-image-wrapper {
        width: 90%;
        margin: 0 5%;
    }
}

@media (max-height: 850px) and (min-width: 1200px) {
    .intro .cover-image-wrapper {
        width: 80%;
        margin: 0 10%;
    }

    .intro .cover-image-wrapper .anim-image {
        width: 125px;
    }

    .intro .cover-image-wrapper .heart-wrapper {
        right: 40px;
        top: 110px;
    }

    .intro .cover-image-wrapper .anim-image.microphones-3d {
        bottom: 210px;
        left: 50px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        bottom: 90px;
        right: 200px;
    }
}

@media (max-height: 750px) and (min-width: 1200px) {
    .intro .cover-image-wrapper {
        width: 70%;
        margin: 0 15%;
    }

    .intro .cover-image-wrapper .anim-image {
        width: 100px;
    }
}

@media (max-height: 650px) and (min-width: 1200px) {
    .intro .cover-image-wrapper {
        width: 60%;
        margin: 0 20%;
    }

    .intro .cover-image-wrapper .heart-wrapper {
        top: 125px;
        right: 115px;
    }

    .intro .cover-image-wrapper .anim-image.microphones-3d {
        bottom: 220px;
        left: 70px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        bottom: 110px;
        right: 240px;
    }
}

@media (max-height: 750px) and (min-width: 1100px) {
    .intro .cover-image-wrapper {
        width: 70%;
        margin: 0 15%;
    }

    .intro .cover-image-wrapper .anim-image {
        width: 80px !important;
    }

    .intro .cover-image-wrapper .heart-wrapper {
        top: 105px;
        right: 35px;
    }

    .intro .cover-image-wrapper .anim-image.microphones-3d {
        bottom: 220px;
        left: 50px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        bottom: 110px;
        right: 210px;
    }
}

@media (max-height: 700px) and (min-width: 900px) {
    .intro .cover-image-wrapper {
        width: 70%;
        margin: 0 15%;
    }

    .intro .cover-image-wrapper .anim-image {
        width: 60px;
    }
}

@media (max-height: 715px) and (min-width: 768px) {
    .intro .cover-image-wrapper {
        width: 80%;
        margin: 0 10%;
    }

    .intro .cover-image-wrapper .anim-image {
        width: 60px !important;
    }
}

@media (max-width: 1368px) {
    .intro .thought-bubble-wrapper {
        width: 340px;
    }

    .intro .thought-bubble-wrapper .thought-bubble-text {
        font-size: 40px;
        line-height: 36px;
    }

    .intro .presented-by {
        margin-top: 20px;
    }
}

@media (max-width: 1100px) {
    .intro .cover-image-wrapper .anim-image.microphones-3d {
        bottom: 210px;
        left: 50px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        right: 220px;
    }
}

@media (max-width: 1050px) {
    .intro .cover-image-wrapper .anim-image {
        width: 100px;
    }
}

@media (max-width: 1024px) {
    .intro .thought-bubble-wrapper {
        width: 275px;
    }

    .intro .thought-bubble-wrapper .thought-bubble-text {
        font-size: 32px;
        line-height: 28px;
    }

    .intro .presented-by {
        margin-top: 30px;
    }
}

@media (max-width: 950px) {
    .intro .cover-image-wrapper .anim-image.headphones-3d {
        right: 190px;
        bottom: 70px;
    }

    .intro .cover-image-wrapper .heart-wrapper {
        top: 110px;
        right: 30px;
    }
}

@media (max-width: 768px) {
    .intro .cover-image-wrapper .cover-image {
        margin-top: 64px;
    }

    .intro .cover-image-wrapper .anim-image {
        width: 60px;
    }

    .intro .cover-image-wrapper .heart-wrapper {
        top: 150px;
        right: -10px;
    }

    .intro .cover-image-wrapper .anim-image.microphones-3d {
        bottom: 150px;
        left: 45px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        right: 160px;
        bottom: 80px;
    }

    .intro .thought-bubble-wrapper {
        width: calc(100% - 20px);
        margin-top: 0;
    }

    .intro .component.thought-bubble .main-section {
        padding: 12px 24px !important;
    }

    .intro .thought-bubble-wrapper .thought-bubble-text {
        font-size: 40px;
        line-height: 34px;
    }

    .intro .presented-by br {
        display: inline-block;
    }

    .intro .presented-by span:first-child {
        line-height: 20px;
    }
}

@media (max-width: 650px) {
    .intro .cover-image-wrapper .heart-wrapper {
        top: 135px;
        right: -30px;
    }

    .intro .cover-image-wrapper .anim-image.microphones-3d {
        bottom: 115px;
        left: 35px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        right: 130px;
        bottom: 60px;
    }
}

@media (max-width: 550px) {
    .intro .cover-image-wrapper .anim-image {
        width: 40px;
    }
}

@media (max-width: 500px) {
    .intro .cover-image-wrapper .heart-wrapper {
        top: 120px;
        right: -60px;
    }

    .intro .cover-image-wrapper .anim-image.microphones-3d {
        bottom: 90px;
        left: 30px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        right: 100px;
        bottom: 50px;
    }
}

@media (max-width: 400px) {
    .intro .cover-image-wrapper .heart-wrapper {
        top: 105px;
        right: -75px;
    }

    .intro .cover-image-wrapper .anim-image.microphones-3d {
        bottom: 60px;
        left: 20px;
    }

    .intro .cover-image-wrapper .anim-image.headphones-3d {
        right: 65px;
        bottom: 30px;
    }
}

/* Paragraphs */

.intro .paragraphs {
    color: var(--color-neutral-white);
}

.intro .paragraphs .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--grid-gap-width);
    margin-bottom: 88px;
}

.intro .paragraphs .row .column {
    border-top: 1px solid var(--color-neutral-white);
    padding-top: 24px;
}

.intro .paragraphs .main-content {
    display: flex;
}

.intro .paragraphs .main-content .left-col {
    width: var(--grid-width-6-col);
    flex-shrink: 0;
}

.intro .paragraphs .main-content .right-col {
    margin-left: var(--grid-gap-width);
}

.intro .paragraphs .main-content .right-col img {
    width: 100%;
}

@media (max-width: 920px) {
    .intro .paragraphs .row {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
    }

    .intro .paragraphs .main-content {
        display: block;
    }

    .intro .paragraphs .main-content .left-col {
        width: auto;
    }
}

@media (max-width: 768px) {
    .intro .paragraphs .main-content {
        flex-direction: column;
    }

    .intro .paragraphs .main-content .left-col {
        margin-bottom: 24px;
    }

    .intro .paragraphs .main-content .right-col {
        margin-left: 0;
    }
}

@media (max-width: 600px) {
    .intro .paragraphs .row {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Your Hosts */

.intro .hosts-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.intro .hosts {
    margin-top: 360px;
    width: var(--grid-width-10-col);
}

.intro .hosts .info {
    display: flex;
    justify-content: space-between;
    color: var(--color-neutral-white);
    margin-bottom: 200px;
    width: 100%;
}

.intro .hosts .info .description {
    width: var(--grid-width-5-col);
}

.intro .hosts .logo-blocks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
}

.intro .hosts .logo-block {
    position: relative;
    z-index: 1;
    display: flex;
}

.intro .hosts .logo-block .top-layer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 22px);
    height: 240px;
    border: 1px solid var(--color-neutral-white);
    background-color: var(--color-neutral-black);
    z-index: 1;
    transition: 0.3s left, 0.3s top;
}

.intro .hosts .logo-block .bottom-layer {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-neutral-white);
    padding: 0 8px 0 24px;
    height: 240px;
    transition: 0.3s left, 0.3s top;
}

.intro .hosts .logo-block .bottom-layer .left-col {
    margin-right: 20px;
    width: 80px;
    flex-shrink: 0;
}

.intro .hosts .logo-block .bottom-layer .left-col svg {
    width: 100%;
}

.intro .hosts .logo-block .bottom-layer .right-col {
    color: var(--color-neutral-white);
}

.intro .hosts .logo-block .vertical-spacer {
    width: 20px;
    height: 100%;
    flex-shrink: 0;
}

.intro .hosts .footnote {
    position: absolute;
    color: var(--color-neutral-dark-gray);
    margin-top: 8px;
}

@media (max-width: 1368px) {
    .intro .hosts .logo-block .bottom-layer {
        flex-direction: column;
    }

    .intro .hosts .logo-block .bottom-layer .left-col {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .intro .hosts .logo-block .bottom-layer .left-col svg {
        margin-top: 44px;
    }
}

@media (max-width: 1150px) {
    .intro .hosts .logo-block .top-layer,
    .intro .hosts .logo-block .bottom-layer {
        height: 300px;
    }
}

@media (max-width: 950px) {
    .intro .hosts .info {
        flex-direction: column;
        margin-bottom: 60px;
    }

    .intro .hosts .info .title {
        margin-bottom: 40px;
        font-size: 50px;
        line-height: 48px;
    }

    .intro .hosts .info .description {
        width: 100%;
        font-size: 21px;
        line-height: 28px;
    }

    .intro .hosts .logo-blocks {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }

    .intro .hosts .logo-block,
    .intro .hosts .logo-block .top-layer,
    .intro .hosts .logo-block .bottom-layer {
        height: 200px;
    }

    .intro .hosts .logo-block .bottom-layer {
        padding: 0 8px 0 24px;
        justify-content: center;
    }

    .intro .hosts .logo-block .bottom-layer .left-col svg {
        margin-top: 0;
    }
}

/* Publishers */

.intro .fresh-with-data {
    display: flex;
    align-items: flex-start;
    margin-top: 360px;
    margin-left: 112px;
}

.intro .fresh-with-data .left-col {
    margin-right: 120px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
}

.intro .fresh-with-data .left-col .fresh-with-data-orange {
    position: absolute;
    margin-top: 9px;
}

.intro .fresh-with-data .left-col .fresh-with-data-orange .leaf {
    position: absolute;
    left: calc(50% + 60px);
    top: -56px;
}

.intro .fresh-with-data .left-col .data-stickers {
    position: absolute;
    left: 20px;
    bottom: -150px;
    display: flex;
    align-items: center;
}

.intro .fresh-with-data .left-col .data-stickers img:nth-child(2) {
    margin-left: -72px;
}

.intro .fresh-with-data .right-col .text {
    color: var(--color-neutral-white);
    margin-bottom: 16px;
}

.intro .fresh-with-data .right-col .footnote {
    color: var(--color-neutral-dark-gray);
    margin-bottom: 88px;
}

.intro .fresh-with-data .right-col .brand-sections {
    margin-top: 96px;
}

.intro .fresh-with-data .right-col .brand-sections .grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro .fresh-with-data .right-col .brand-sections .brand-row:not(:last-child) {
    margin-bottom: 20px;
}

.intro .fresh-with-data .right-col .brand-sections .brand-title {
    color: var(--color-neutral-white);
    text-transform: uppercase;
}

.intro .fresh-with-data .right-col .brand-sections .brand-logos {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
}

.intro .fresh-with-data .right-col .brand-sections .brand-logos img {
    height: 80px;
}

.intro .fresh-with-data .right-col .brand-sections .brand-logos img:not(:last-child) {
    margin-right: 32px;
}

@media (max-width: 1120px) {
    .intro .fresh-with-data .left-col img.background-image {
        width: 282.5px;
    }

    .intro .fresh-with-data .left-col .data-stickers {
        bottom: -80px;
    }

    .intro .fresh-with-data .left-col .data-stickers img:first-child {
        width: 160px;
    }

    .intro .fresh-with-data .left-col .data-stickers img:nth-child(2) {
        width: 102.5px;
        margin-left: -40px;
    }

    .intro .fresh-with-data .left-col img.base {
        width: 218px;
    }

    .intro .fresh-with-data .left-col img.left {
        width: 40.5px;
    }
}

@media (max-width: 768px) {
    .intro .fresh-with-data {
        flex-direction: column;
        margin-left: 0;
    }

    .intro .fresh-with-data .left-col {
        width: var(--grid-width-6-col);
        margin-right: 0;
        margin-bottom: 300px;
    }

    .intro .fresh-with-data .left-col .data-stickers {
        left: auto;
        bottom: -150px;
    }
}

/* Obsessed */

.intro .obsessed {
    width: 100%;
    margin-top: 240px;
    overflow: hidden;
}

.intro .obsessed .heart-3d {
    display: block;
    width: 350px;
    margin: 0 auto;
}

.intro .obsessed .ticker-wrapper {
    margin-top: -108px;
}

@media (max-width: 768px) {
    .intro .obsessed .heart-3d {
        width: 208px;
    }

    .intro .obsessed .ticker-wrapper {
        margin-top: -42px;
    }
}

/* Podcasts */

.intro .podcasts {
    overflow: hidden;
    margin-top: 240px;
}

.intro .podcasts .info {
    display: flex;
    justify-content: space-between;
    width: var(--grid-width-10-col);
    margin-bottom: 260px;
    color: var(--color-neutral-white);
}

.intro .podcasts .info .title {
    width: var(--grid-width-4-col);
}

.intro .podcasts .info .description {
    width: var(--grid-width-5-col);
}

.intro .podcasts .horizontal-scroll-block {
    background-color: var(--color-solid-gold);
}

.intro .podcasts .horizontal-scroll-block .details {
    display: flex;
    margin-top: 64px;
    margin-bottom: 20px;
}

.intro .podcasts .horizontal-scroll-block .detail {
    width: 650px;
    flex-shrink: 0;
}

.intro .podcasts .horizontal-scroll-block .detail:not(:last-child) {
    margin-right: 300px;
}

@media (max-width: 1368px) {
    .intro .podcasts .info .title {
        font-size: 50px;
        line-height: 48px;
    }

    .intro .podcasts .info .description {
        font-size: 21px;
        line-height: 28px;
    }

    .intro .podcasts .horizontal-scroll-block .detail .stat {
        font-size: 60px;
        line-height: 60px;
    }
}

@media (max-width: 768px) {
    .intro .podcasts .info {
        flex-direction: column;
        width: calc(100% - calc(var(--grid-gap-width) * 2));
    }

    .intro .podcasts .info .title,
    .intro .podcasts .info .description {
        width: 100%;
    }

    .intro .podcasts .info .title {
        margin-bottom: 40px;
    }

    .intro .podcasts .horizontal-scroll-block .detail {
        width: 500px;
    }

    .intro .podcasts .horizontal-scroll-block .detail:not(:last-child) {
        margin-right: 200px;
    }

    .intro .podcasts .horizontal-scroll-block .detail .stat {
        font-size: 40px;
        line-height: 40px;
    }

    .intro .podcasts .horizontal-scroll-block img {
        height: 144px;
    }
}

@media (max-width: 500px) {
    .intro .podcasts .horizontal-scroll-block .detail {
        width: 320px;
    }

    .intro .podcasts .horizontal-scroll-block .detail:not(:last-child) {
        margin-right: 100px;
    }
}

@media (max-height: 1020px) and (min-width: 1369px) {
    .intro .podcasts .horizontal-scroll-block .detail .stat {
        font-size: 60px;
        line-height: 60px;
    }
}

@media (max-height: 850px) and (min-width: 1369px) {
    .intro .podcasts .horizontal-scroll-block img {
        height: 150px;
    }
}

@media (max-height: 860px) and (min-width: 768px) and (max-width: 1368px) {
    .intro .podcasts .horizontal-scroll-block img {
        height: 125px;
    }
}

/* Unlock Data Form */

.intro .unlock-data-form {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: calc(100% - 65px - 57px);
    background-color: rgba(0, 0, 0, 80%);
    padding: 65px 0 57px 0;
    overflow-y: auto;
}

.intro .unlock-data-form .container {
    padding: 10vh 0;
}

.intro .unlock-data-form .form-wrapper {
    margin: 0 auto;
    padding: 40px;
    border: 1px solid var(--color-neutral-white);
    color: var(--color-neutral-white);
    background-color: var(--color-neutral-black);
}

.intro .unlock-data-form .form-wrapper .form-header {
    margin-bottom: 40px;
}

.intro .unlock-data-form .form-wrapper .title {
    margin-bottom: 16px;
}

.intro .unlock-data-form .form-wrapper .error-message {
    color: var(--color-solid-bright-peach);
    margin-top: 16px;
}

.intro .unlock-data-form hr {
    margin: 0;
    border: 0;
    border-top: 1px solid var(--color-neutral-dark-gray);
    margin-bottom: 24px;
}

.intro .unlock-data-form .required-star {
    color: var(--color-solid-bright-peach);
}

.intro .unlock-data-form .input-wrapper {
    position: relative;
    width: 100%;
}

.intro .unlock-data-form .input-wrapper input {
    width: 100%;
    background-color: transparent;
    outline: 0;
    border: 0;
    padding: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-neutral-dark-gray);
    color: var(--color-neutral-white);
    transition: 0.3s border, 0.3s padding;
}

.intro .unlock-data-form .input-wrapper input.active {
    border-bottom: 2px solid var(--color-neutral-white);
    padding-bottom: 9px;
}

.intro .unlock-data-form .input-wrapper input:focus {
    outline: 1px dashed #00B2FF;
}

.intro .unlock-data-form .dropdown-wrapper label {
    display: block;
    margin-bottom: 8px;
}

.intro .unlock-data-form .dropdown-wrapper select {
    width: 100%;
    background-color: transparent;
    outline: 0;
    border: 0;
    padding: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-neutral-dark-gray);
    color: var(--color-neutral-dark-gray);
    transition: 0.3s border, 0.3s padding, 0.3s color;
}

.intro .unlock-data-form .dropdown-wrapper select.active {
    border-bottom: 2px solid var(--color-neutral-white);
    color: var(--color-neutral-white);
}

.intro .unlock-data-form .radio-select-wrapper .input-title {
    color: var(--color-neutral-white);
    margin-bottom: 16px;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group {
    display: flex;
    cursor: pointer;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group:not(:last-child) {
    margin-bottom: 16px;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group .custom-radio-button {
    position: relative;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group input {
    margin-right: 8px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group .custom-radio-button:focus {
    outline: 1px dashed #00B2FF;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group .custom-radio-button .square-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: var(--color-neutral-black);
    border: 1px solid var(--color-neutral-dark-gray);
}

.intro .unlock-data-form .radio-select-wrapper .radio-group .custom-radio-button .square-radio .inner-square {
    opacity: 0;
    width: 6px;
    height: 6px;
    margin: 4px;
    background-color: var(--color-neutral-white);
    transition: 0.3s opacity;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group .custom-radio-button input:checked ~ .square-radio {
    border-color: var(--color-neutral-white);
}

.intro .unlock-data-form .radio-select-wrapper .radio-group .custom-radio-button input:checked ~ .square-radio .inner-square {
  opacity: 1;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group .radio-label {
    margin-left: 24px;
    color: var(--color-neutral-dark-gray);
    transition: 0.3s color;
}

.intro .unlock-data-form .radio-select-wrapper .radio-group.active .radio-label {
    color: var(--color-neutral-white);
}

.intro .unlock-data-form .icon {
    position: absolute;
    top: 1px;
    right: 0;
}

.intro .unlock-data-form .submit-button {
    border: 1px solid var(--color-neutral-white);
    width: 160px;
    text-align: center;
    padding: 8px 0;
    text-transform: uppercase;
    color: var(--color-neutral-white);
    cursor: pointer;
    margin-right: 16px;
    transition: 0.3s border-color, 0.3s color;
    user-select: none;
}

.intro .unlock-data-form .submit-button.loading {
    border-color: var(--color-neutral-dark-gray-2);
    color: var(--color-neutral-dark-gray-2);
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .intro .unlock-data-form {
        background-color: var(--color-neutral-black);
        height: calc(100% - 81px);
        padding: 81px 0 0 0;
    }

    .intro .unlock-data-form .container {
        padding: 50px 0;
    }

    .intro .unlock-data-form .form-wrapper {
        width: auto !important;
        border: 0;
        padding: 0;
    }

    .intro .unlock-data-form .form-wrapper .title {
        font-size: 32px;
        line-height: 34px;
        margin-bottom: 8px;
    }

    .intro .unlock-data-form .form-wrapper .subtitle {
        font-size: 16px;
        line-height: 24px;
    }

    .intro .unlock-data-form .submit-button {
        margin-right: 0;
    }
}

/* Unlock Data Form - SXM Media */

.intro .unlock-data-form .adswizz-form .columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 48px;
    margin-bottom: 40px;
}

.intro .unlock-data-form .adswizz-form .column .row:not(:last-child) {
    margin-bottom: 16px;
}

.intro .unlock-data-form .adswizz-form .section-title {
    color: var(--color-neutral-white);
    margin-bottom: 24px;
}

.intro .unlock-data-form .adswizz-form .input-wrapper:not(:last-child) {
    margin-bottom: 24px;
}

.intro .unlock-data-form .adswizz-form .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.intro .unlock-data-form .adswizz-form .footnotes {
    display: flex;
    color: var(--color-neutral-dark-gray);
}

.intro .unlock-data-form .adswizz-form .footnotes .footnote-left {
    width: var(--grid-width-2-col);
    margin-right: 32px;
}

.intro .unlock-data-form .adswizz-form .footnotes .footnote-right {
    width: var(--grid-width-2-col);
}

.intro .unlock-data-form .adswizz-form .radio-label.radio-small-text {
    color: var(--color-neutral-white);
    padding-top: 3px;
}

.intro .unlock-data-form .adswizz-form .agree-to-terms {
    margin-top: 8px;
}

.intro .unlock-data-form .adswizz-form .agree-to-terms a {
    color: #00B2FF;
}

@media (max-width: 1368px) {
    .intro .unlock-data-form .adswizz-form .footnotes .footnote-left,
    .intro .unlock-data-form .adswizz-form .footnotes .footnote-right {
        width: var(--grid-width-5-col);
    }
}

@media (max-width: 1122px) {
    .intro .unlock-data-form .adswizz-form .radio-label.radio-small-text {
        padding-top: 0;
    }
}

@media (max-width: 768px) {
    .intro .unlock-data-form .adswizz-form .columns {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 32px;
    }

    .intro .unlock-data-form .adswizz-form .input-wrapper:not(:last-child) {
        margin-bottom: 16px;
    }

    .intro .unlock-data-form .adswizz-form .bottom-row {
        flex-direction: column;
    }

    .intro .unlock-data-form .adswizz-form .bottom-row .left-col {
        margin-bottom: 24px;
    }

    .intro .unlock-data-form .adswizz-form .bottom-row .left-col .footnotes {
        flex-direction: column;
    }

    .intro .unlock-data-form .adswizz-form .bottom-row .left-col .footnotes .footnote-left,
    .intro .unlock-data-form .adswizz-form .bottom-row .left-col .footnotes .footnote-right {
        width: auto;
    }

    .intro .unlock-data-form .adswizz-form .bottom-row .left-col .footnotes .footnote-left {
        margin-right: 0;
        margin-bottom: 16px;
    }
}

/* Unlock Data Form - AdsWizz */

.intro .unlock-data-form .sxm-media-form .fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    column-gap: 48px;
    margin-bottom: 40px;
}

.intro .unlock-data-form .sxm-media-form .fields .field-wrapper {
    width: 100%;
}

.intro .unlock-data-form .sxm-media-form .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.intro .unlock-data-form .sxm-media-form .bottom-row .left-col {
    width: var(--grid-width-3-col);
}

.intro .unlock-data-form .sxm-media-form .bottom-row .footnote {
    color: var(--color-neutral-dark-gray);
}

@media (max-width: 1368px) {
    .intro .unlock-data-form .sxm-media-form .bottom-row .left-col {
        width: var(--grid-width-6-col);
    }
}

@media (max-width: 768px) {
    .intro .unlock-data-form .sxm-media-form .bottom-row {
        flex-direction: column;
    }

    .intro .unlock-data-form .sxm-media-form .bottom-row .left-col {
        width: 100%;
        margin-bottom: 24px;
    }
}

@media (max-width: 550px) {
    .intro .unlock-data-form .sxm-media-form .fields {
        grid-template-columns: repeat(1, 1fr);
    }
}
