.component.cookie-banner {
    background-color: var(--color-solid-gold);
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 9;
    transition: 0.3s transform;
}

.component.cookie-banner .container {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.component.cookie-banner .left-col {
    color: var(--color-neutral-black);
}

.component.cookie-banner .agree-button {
    padding: 4px 24px;
    border: 1px solid var(--color-neutral-black);
    color: var(--color-neutral-black);
    background-color: var(--color-solid-gold);
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s background-color;
}

.component.cookie-banner .agree-button:hover {
    background-color: var(--color-solid-tangerine);
}

@media (max-width: 530px) {
    .component.cookie-banner .left-col,
    .component.cookie-banner .right-col .agree-button {
        font-size: 13px;
        line-height: 16px;
    }

    .component.cookie-banner .container {
        flex-direction: column;
    }

    .component.cookie-banner .left-col {
        margin-bottom: 12px;
    }
}
